import { useManufacturerApi } from "@multicase/portal-api/manufacturer";
import { Manufacturer } from "@multicase/portal-api/manufacturer";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import type { AuthHeaders } from "@multicase/portal-api";

export function useManufacturers() {
  const manufacturer = ref<Manufacturer>(new Manufacturer());
  const manufacturers = ref<Manufacturer[]>([]);

  const manufacturerApi = useManufacturerApi();
  const { getRequest, loading, saving, error } = useRequests();

  const fetchManufacturerById = async (id: string): Promise<Manufacturer> => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      manufacturer.value = await manufacturerApi.get(authHeaders, id);
      manufacturerUnmodified.value = cloneDeep(manufacturer.value);
    });
    return manufacturer.value;
  };

  const manufacturerUnmodified = ref<Manufacturer>(new Manufacturer());
  const isChanged = computed(() => {
    return !isEqual(manufacturer.value, manufacturerUnmodified.value);
  });

  return {
    manufacturers,
    manufacturer,
    isChanged,
    fetchManufacturerById,
    loading,
    saving,
    error,
  };
}
