<template>
  <div class="c-order-lines min-w-full" :class="{ 'c-fullscreen': showAdvancedSearch == true }">
    <DataTable
      ref="tableOrderLines"
      :key="renderKey"
      :value="orderLines"
      striped-rows
      data-key="id"
      class="c-datatable c-order-table"
      responsive-layout="scroll"
      selection-mode="single"
      :row-class="addRowClass"
      data-testid="order-line-result"
      :resizable-columns="true"
      column-resize-mode="fit"
      :reorderable-columns="true"
      :sort-field="sortField"
      :sort-order="sortOrder"
      removable-sort
      tabindex="-1"
      pt:header:class="p-0"
      @row-reorder="updateOrderLines($event.value)"
      @row-select="onRowSelected"
      @row-dblclick="onSelectOrderLine"
      @row-contextmenu="onRowContextMenu"
      @column-reorder="onColumnReorder"
      @column-resize-end="onColumnResizeEnd"
      @sort="onSort"
    >
      <template #header>
        <div class="flex justify-between">
          <div class="flex-1 text-color font-semibold leading-6">{{ t("order.footer.orderline") }}</div>
          <div class="ml-2">
            <Button data-testid="btn-column-chooser" severity="secondary" variant="outlined" @click="openColumnChooser">
              <i class="pi pi-list"></i>
            </Button>
          </div>
        </div>
      </template>

      <Column
        v-for="col of selectedColumnsComputed as unknown as DataTableColumnExt[]"
        :key="col.field"
        :field="col.field"
        :header="t(col.tooltip ? 'order.properties.empty' : col.header)"
        :column-key="col.field"
        :class="col.class"
        :sortable="col.sortable"
        :row-reorder="col.rowReorder"
        :pt="{
          headerCell: {
            id: col.field,
            class: col.field,
            tabindex: -1,
          },
          columntitle: {
            class: col.field === OrderLineColumns.QuantityDelivered ? 'hidden' : col.field,
          },
          bodyCell: ({
            instance: {
              // @ts-ignore
              rowData,
            },
          }) => ({
            class: {
              clickable: true ? rowData.quantityDelivered != rowData.quantity : false,
            },
          }),
          rowToggleButton: ({
            instance: {
              // @ts-ignore
              rowData,
            },
          }) => ({
            class: {
              hidden: rowData.quantityDelivered === rowData.quantity,
              clickable: true,
            },
          }),
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template v-if="col.field === OrderLineColumns.QuantityDelivered" #header>
          <div
            v-tooltip.bottom="{ value: t(col.tooltip || ''), showDelay: 1000, hideDelay: 300 }"
            class="font-semibold"
          >
            {{ t(col.header) }}
          </div>
        </template>

        <template v-if="col.field != OrderLineColumns.ReorderHandle" #body="{ data, field, index }">
          <template v-if="col.field === OrderLineColumns.PositionNumber">
            <span>{{ data.positionNumber }}</span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.ProductImages">
            <img
              v-if="data.product.primaryImageUrl != undefined && data.product.primaryImageUrl.length > 0"
              :src="resizeImage(data.product.primaryImageUrl, 60, 60)"
              class="c-product-image"
              :class="{ 'c-product-image-v': visibleColumn }"
              :draggable="allowEditOrder"
            />
          </template>
          <template v-else-if="col.field === OrderLineColumns.ProductNumber">
            {{ data.product.productNumber }}
          </template>
          <template v-else-if="col.field === OrderLineColumns.ProductName">
            <div class="w-60 whitespace-nowrap text-ellipsis">{{ data.product.name }}</div>
          </template>
          <template v-else-if="col.field === OrderLineColumns.Description">
            <div class="w-60 whitespace-nowrap text-ellipsis">{{ data.product.description }}</div>
          </template>
          <template v-else-if="col.field === OrderLineColumns.Product">
            <div class="c-product-description">{{ data.product.productNumber }}</div>
            <div class="c-product-description">{{ data.product.name }}</div>
            <div class="c-product-description">{{ data.product.description }}</div>
          </template>
          <template v-else-if="col.field === OrderLineColumns.Quantity">
            <OrderLineInput
              v-if="editingRowIndex === index"
              :id="`order-line-quantity-${index}`"
              :disabled="!allowChangeQuantity(data)"
              input-class="c-row-input w-16"
              :value="data[field as keyof typeof data]"
              :min-value="minQuantity(data)"
              :max-value="maxQuantity(data)"
              :allow-empty="false"
              :set-focus="true"
              :data-testid="`order-line-quantity-${index}`"
              @on-update-value="onUpdateQuantity($event, data)"
              @keydown.enter.prevent="stopEditRow(index)"
              @keydown.esc.prevent="stopEditRow(index)"
            />

            <span v-if="editingRowIndex !== index" :data-testid="`order-line-quantity-${index}`" data>{{
              data[field as keyof typeof data]
            }}</span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.QuantityForDelivery">
            {{ data.quantityForDelivery }}
          </template>
          <template v-else-if="col.field === OrderLineColumns.QuantityDelivered">
            {{ data.quantityDelivered }}
          </template>
          <template v-else-if="col.field === OrderLineColumns.QuantityInBackOrder">
            {{ data.quantityInBackOrder }}
          </template>
          <template v-else-if="col.field === OrderLineColumns.BackOrderDate">
            <span :data-testid="`order-line-back-order-date-${index}`">
              {{ d(data[field as keyof typeof data], "short") }}
            </span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.ShippingDate">
            <OrderDate
              v-if="editingRowIndex === index"
              :id="`order-line-delivery-date-${index}`"
              v-model:date-time="data[field as keyof typeof data]"
              :disabled="!allowEditOrder && isPartiallyDelivered(data)"
              :data-test-id="`order-line-delivery-date-${index}`"
            />
            <span v-if="editingRowIndex !== index" :data-testid="`order-line-delivery-date-${index}`">
              {{ d(data[field as keyof typeof data], "short") }}</span
            >
          </template>
          <template v-else-if="col.field === OrderLineColumns.FifoDate">
            <OrderDate
              v-if="editingRowIndex === index"
              :id="`order-line-fifo-date-${index}`"
              v-model:date-time="data[field as keyof typeof data]"
              :disabled="!allowEditOrder && isPartiallyDelivered(data)"
              :data-test-id="`order-line-fifo-date-${index}`"
            />
            <span v-if="editingRowIndex !== index" :data-testid="`order-line-fifo-date-${index}`">
              {{ d(data[field as keyof typeof data], "short") }}</span
            >
          </template>
          <template v-else-if="col.field === OrderLineColumns.Price">
            <OrderLineInput
              v-if="editingRowIndex === index"
              :id="`order-line-price-${index}`"
              :disabled="!allowEditOrder && isPartiallyDelivered(data)"
              input-class="c-row-input w-24"
              :min-value="0"
              :digits="2"
              :value="data[field as keyof typeof data]"
              :allow-empty="false"
              @on-update-value="onUpdatePriceExVat($event, data)"
              @keydown.enter.prevent="stopEditRow(index)"
              @keydown.esc.prevent="stopEditRow(index)"
            />
            <span v-if="editingRowIndex !== index" :data-testid="`order-line-price-${index}`">{{
              n(data[field as keyof typeof data], "decimal")
            }}</span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.PriceIncVat">
            <OrderLineInput
              v-if="editingRowIndex === index"
              :id="`order-line-price-inc-${index}`"
              :disabled="!allowEditOrder && isPartiallyDelivered(data)"
              input-class="c-row-input w-24"
              :digits="2"
              :min-value="0"
              :value="data[field as keyof typeof data]"
              :allow-empty="false"
              :data-testid="`order-line-price-inc-vat-${index}`"
              @on-update-value="onUpdatePriceIncVat($event, data)"
              @keydown.enter.prevent="stopEditRow(index)"
              @keydown.esc.prevent="stopEditRow(index)"
            />

            <span v-if="editingRowIndex !== index" :data-testid="`order-line-price-inc-vat-${index}`">{{
              n(data[field as keyof typeof data], "decimal")
            }}</span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.CostPrice">
            <span :data-testid="`order-line-cost-price-${index}`">
              {{ n(data[field as keyof typeof data], "decimal") }}</span
            >
          </template>
          <template v-else-if="col.field === OrderLineColumns.DiscountPercentage">
            <OrderLineInput
              v-if="editingRowIndex === index"
              :id="`order-line-discount-percent-${index}`"
              :disabled="!allowEditOrder && isPartiallyDelivered(data)"
              input-class="c-row-input w-20"
              :value="data[field as keyof typeof data]"
              :digits="2"
              :min-value="0"
              :max-value="100"
              suffix="%"
              :allow-empty="false"
              :data-testid="`order-line-discount-percent-${index}`"
              @on-update-value="onUpdateDiscount($event, data)"
              @keydown.enter.prevent="stopEditRow(index)"
              @keydown.esc.prevent="stopEditRow(index)"
            />
            <span v-if="editingRowIndex !== index" :data-testid="`order-line-discount-percent-error`">{{
              n(data[field as keyof typeof data], "decimal")
            }}</span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.ContributionMargin">
            <OrderLineInput
              v-if="editingRowIndex === index"
              :id="`order-line-contribution-margin-${index}`"
              :disabled="!allowEditOrder && isPartiallyDelivered(data)"
              input-class="c-row-input w-20"
              :value="data[field as keyof typeof data]"
              :digits="2"
              :max-value="99.99"
              suffix="%"
              :allow-empty="false"
              :data-testid="`order-line-contribution-margin-${index}`"
              @on-update-value="onUpdateContributionMargin($event, data)"
              @keydown.enter.prevent="stopEditRow(index)"
              @keydown.esc.prevent="stopEditRow(index)"
            />

            <span v-if="editingRowIndex !== index" :data-testid="`order-line-contribution-margin-${index}`">{{
              n(data[field as keyof typeof data], "decimal")
            }}</span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.SumLine">
            <span :data-testid="`order-line-sum-${index}`">
              {{ n(data[field as keyof typeof data], "decimal") }}
            </span>
          </template>
          <template v-else-if="col.field === OrderLineColumns.EditHandle">
            <div v-if="editingRowIndex !== index" class="c-orderline-select">
              <div class="flex justify-center items-center">
                <i
                  role="button"
                  class="pi pi-ellipsis-h c-context-menu-button"
                  :data-testid="`context-menu-button-${index}`"
                  @click="(e) => openEllipsisContextMenu(e, data, index)"
                >
                </i>
              </div>
            </div>

            <div v-else class="c-orderline-edit">
              <Button
                text
                type="button"
                class="c-orderline-close-button"
                data-testid="order-line-close"
                :tabindex="editingRowIndex === index ? 0 : -1"
                @click="stopEditRow(-1)"
              >
                <span class="c-row-add material-symbols-outlined material-filled"> check </span>
              </Button>
            </div>
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>
    </DataTable>

    <RowContextMenu
      ref="ellipsisContextMenuRef"
      :allow-delete="allowDeleteRowContextMenu(selectedRow)"
      :allow-edit="allowEditRowContextMenu(selectedRow)"
      data-testId="row-context-menu"
      @open-product-info="openProductInfoDialog(selectedRow.product.id)"
      @select-row="selectRow(selectedRow, selectedRowIndex)"
      @delete-order-line="(e) => onConfirmDelete(e.originalEvent, selectedRow)"
    />
    <PopOverColumnChooser
      ref="chooserRef"
      v-model:selected-columns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      @reset-columns="resetColumns"
    />
    <ProductInfoDialog v-if="showDialog" v-model:show-dialog="showDialog" :product-id="selectedRow.product.id" />

    <small v-if="val.orderLines.$error" class="p-error" data-testid="order-line-error">
      {{ val.orderLines.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";

import { OrderLine } from "@/models/order/OrderLine";
import { useImageService } from "@/api/image/ImageService";
import { useOrderCalculation } from "@/utils/calculation/OrderCalculation";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import OrderLineInput from "./OrderLineInput.vue";
import type {
  DataTableRowContextMenuEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import cloneDeep from "lodash.clonedeep";
import { useConfirm } from "primevue/useconfirm";
import ProductInfoDialog from "./ProductInfoDialog.vue";
import RowContextMenu from "./RowContextMenu.vue";
import { OrderStatus } from "@/models/order/OrderStatus";

import { OrderLineColumns } from "@/models/order/OrderLineColumns";
import { useTablePreferences } from "@cumulus/components";
import { type DataTableColumnExt, PopOverColumnChooser } from "@cumulus/components";
import OrderDate from "./search/OrderDate.vue";

const confirm = useConfirm();
const { resizeImage } = useImageService();

const { t, n, d } = useI18n();
const tableOrderLines = ref();
const componentElements = ref<HTMLElement[]>([]);

const props = defineProps<{
  showAdvancedSearch: boolean;
  allowAddProducts: boolean;
  allowEditOrder: boolean;
  orderStatus: OrderStatus;
}>();

const selectedProductId = defineModel<string | null>("selectedProductId", { required: true });
const focusFirstOrderLine = defineModel<boolean>("focusFirstOrderLine", { required: true });
const orderLines = defineModel<OrderLine[]>("orderLines", { required: true });
const emit = defineEmits<{
  (e: "reCalculateOrderTotalPrice", value: void): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
}>();

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    emit("update:sortOrder", -sortOrder.value);
    emit("update:sortField", sortField);
  });
};

const orderLineColumns = [
  { field: "reorderHandle", header: "order.properties.reordre-handle", rowReorder: true, sortable: false },
  {
    field: "positionNumber",
    header: "order.properties.position-number",
    class: "text-center p-0",
    sortable: true,
  },
  { field: "product.productImages", header: "order.properties.main-image", sortable: false },
  {
    field: "product.productNumber",
    header: "order.properties.product-number",
    sortable: true,
  },
  { field: "product.name", header: "order.properties.product-name", sortable: true },
  { field: "product.description", header: "order.product.description", sortable: true },
  { field: "product.product", header: "order.product.product", sortable: false },
  {
    field: "quantity",
    header: "order.properties.quantity",
    class: "c-col-quantity text-center p-0",
    sortable: true,
  },
  {
    field: "quantityForDelivery",
    header: "order.properties.for-delivery",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "quantityDelivered",
    header: "order.properties.delivered",
    class: "text-center p-0",
    sortable: true,
    tooltip: "order.order-lines.delivered",
  },
  {
    field: "quantityInBackOrder",
    header: "order.properties.back-order",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "backOrderDate",
    header: "order.properties.back-order-date",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "shippingDate",
    header: "order.properties.delivery-date",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "fifoDate",
    header: "order.properties.fifo-date",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "price",
    header: "order.properties.price-excl-vat",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "priceIncVat",
    header: "order.properties.price-incl-vat",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "costPrice",
    header: "order.properties.cost-price",
    class: "text-center p-0",
    sortable: true,
  },
  {
    field: "discountPercentage",
    header: "order.properties.discounted-product-percentage",
    class: "text-center",
    sortable: true,
  },
  {
    field: "contributionMargin",
    header: "order.properties.contribution-margin",
    class: "text-center",
    sortable: true,
  },
  { field: "sumLine", header: "order.properties.sum-excl-vat", class: "text-right", sortable: true },
  { field: "editHandle", header: "order.properties.edit-handle", class: "c-edit-row", sortable: false },
];

const chooserRef = ref();

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};
defineExpose({
  openColumnChooser,
});

const { selectedColumnsComputed, orderedColumns, renderKey, onColumnResizeEnd, onColumnReorder, resetColumns } =
  useTablePreferences("orderLines", orderLineColumns, null);

const allowChangeQuantity = (orderLine: OrderLine) => {
  return !(orderLine.quantity - orderLine.quantityDelivered === 0);
};

const filteredColumns = computed(() => {
  if (props.allowEditOrder) {
    return orderedColumns.value.filter(
      (col) => col.field !== OrderLineColumns.ReorderHandle && col.field !== OrderLineColumns.EditHandle,
    );
  } else {
    return orderedColumns.value.filter(
      (col) => col.field !== OrderLineColumns.ReorderHandle && col.field !== OrderLineColumns.EditHandle,
    );
  }
});

const { updateQuantity, updatePriceExVat, updatePriceIncVat, updateContributionMargin, updateDiscount } =
  useOrderCalculation();

const editingRowIndex = ref<number | null>(null);

const showDialog = ref<boolean>(false);
const ellipsisContextMenuRef = ref();
const previouslyFocusedRow = ref();
const selectedRow = ref<OrderLine>(new OrderLine());
const selectedRowIndex = ref<number>(0);

const sortField = ref("");
const sortOrder = ref(-1);

const isPartiallyDelivered = (orderLine: OrderLine) => {
  return orderLine.quantityForDelivery != 0 && orderLine.quantityDelivered !== 0;
};

const addRowClass = (data: OrderLine) => {
  if (lineIsFullyDelivered(data)) return "c-line-done";
  return [`c-search-result-row c-sr-id-${data.id}`];
};

const lineIsFullyDelivered = (orderLine: OrderLine) => {
  return orderLine.quantity - orderLine.quantityDelivered === 0;
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (editingRowIndex.value === event.index) return;

  editingRowIndex.value = null;
  selectedProductId.value = event.data.product.id;
  if (event.originalEvent != null) {
    if (!(event.originalEvent instanceof KeyboardEvent)) {
      return;
    }
    if (event.originalEvent.key === "Enter" && props.allowEditOrder) {
      selectRow(event.data, event.index);
      return;
    }
  }
};

const onSelectOrderLine = (event: DataTableRowDoubleClickEvent) => {
  if (props.orderStatus === OrderStatus.Open) {
    selectRow(event.data, event.index);
  }
  if (
    !(event.data.quantity - event.data.quantityDelivered === 0) &&
    props.orderStatus === OrderStatus.PartiallyDelivered
  ) {
    selectRow(event.data, event.index);
  }
};

const selectRow = (orderLine: OrderLine, index: number) => {
  editingRowIndex.value = index;
  selectedProductId.value = orderLine.product.id;
};

const stopEditRow = (index: number) => {
  editingRowIndex.value = null;

  if (tableOrderLines.value && index > -1) {
    tableOrderLines.value.$el.querySelectorAll("tbody tr")[index].focus();
  }
};
const visibleColumn = computed<boolean>(() => {
  if (selectedColumnsComputed.value.find((c: { field: string }) => c.field === "product.product")) {
    return true;
  }
  return false;
});

const onUpdateQuantity = (quantity: number, orderLine: OrderLine) => {
  updateQuantity(quantity, orderLine);

  emit("reCalculateOrderTotalPrice");
};

const onUpdatePriceExVat = (priceExVat: number, orderLine: OrderLine) => {
  updatePriceExVat(priceExVat, orderLine);

  emit("reCalculateOrderTotalPrice");
};

const onUpdatePriceIncVat = (priceIncVat: number, orderLine: OrderLine) => {
  updatePriceIncVat(priceIncVat, orderLine);

  emit("reCalculateOrderTotalPrice");
};

const onUpdateContributionMargin = (contributionMargin: number, orderLine: OrderLine) => {
  updateContributionMargin(contributionMargin, orderLine);

  emit("reCalculateOrderTotalPrice");
};

const onUpdateDiscount = (discount: number, orderLine: OrderLine) => {
  updateDiscount(discount, orderLine);

  emit("reCalculateOrderTotalPrice");
};

const minQuantity = (orderLine: OrderLine) => {
  const alreadyDeliveredOrInPicking = (orderLine.quantityDelivered ?? 0) + (orderLine.quantityAllocatedForPicking ?? 0);
  if (alreadyDeliveredOrInPicking > 0) {
    return alreadyDeliveredOrInPicking;
  }

  if (props.allowEditOrder) {
    return 1;
  }

  return 1;
};

const maxQuantity = (orderLine: OrderLine) => {
  if (
    props.orderStatus === OrderStatus.PartiallyDelivered &&
    orderLine.quantityDelivered + orderLine.quantityInBackOrder + orderLine.quantityForDelivery > 0
  ) {
    return orderLine.quantity;
  }
};

//Only props work with out cloneDeep og computed in useVuelidate(...)
//Failed with "normal" computed and using currentOrder.value
const vuelidateHack = computed(() => {
  return { orderLines: cloneDeep(orderLines.value) };
});

const rules = {
  orderLines: {
    required: helpers.withMessage(
      t("validations.at-least-one", {
        property: t("order.order-line").toLowerCase(),
      }),
      required,
    ),
    minLength: minLength(1),
  },
};

const allowEditRowContextMenu = (orderLine: OrderLine) => {
  const allowEditRow = !(props.allowEditOrder && isPartiallyDelivered);
  return (
    props.orderStatus === OrderStatus.Open ||
    (props.orderStatus === OrderStatus.PartiallyDelivered && allowEditRow && !lineIsFullyDelivered(orderLine))
  );
};

const allowDeleteRowContextMenu = (orderLine: OrderLine) => {
  const allowDeleteOrderLine = !(!props.allowEditOrder && isPartiallyDelivered(orderLine));
  return (
    props.orderStatus === OrderStatus.Open ||
    (props.orderStatus === OrderStatus.PartiallyDelivered && allowDeleteOrderLine && !lineIsFullyDelivered(orderLine))
  );
};

const val = useVuelidate(rules, vuelidateHack);

onMounted(async () => {
  componentElements.value = Array.from(document.getElementsByClassName("c-order")) as HTMLElement[];
});

const deleteOrderLine = (orderLine: OrderLine) => {
  const index = orderLines.value?.findIndex((item) => item.id === orderLine.id);
  if (index !== -1) {
    orderLines.value?.splice(index, 1);
    for (let index = 0; index < orderLines.value.length; index++) {
      orderLines.value[index].positionNumber = index + 1;
    }

    emit("reCalculateOrderTotalPrice");
    focusFirstOrderLine.value = true;
  }
};

const onConfirmDelete = (event: Event, orderLine: OrderLine) => {
  const targetElement = event.target as HTMLElement;
  confirm.require({
    message: t("common.delete-confirm", { posNr: orderLine.positionNumber }),
    header: t("common.confirm"),
    icon: "pi pi-exclamation-triangle",
    position: "center",
    rejectProps: {
      label: t("common.no"),
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: t("common.yes"),
    },
    accept: async () => {
      deleteOrderLine(orderLine);
    },
    reject: async () => {
      if (document.body.contains(targetElement)) {
        targetElement.focus();
      } else if (previouslyFocusedRow.value) {
        previouslyFocusedRow.value.focus();
      }
    },
  });
};

const updateOrderLines = (orderlines: OrderLine[]) => {
  if (!props.allowEditOrder) return;

  orderlines.forEach((o: OrderLine, i: number) => {
    o.positionNumber = i + 1;
  });
  orderLines.value = orderlines;
  emit("reCalculateOrderTotalPrice");
};

const onFocusFirstOrderLine = () => {
  if (orderLines.value.length > 0) {
    tableOrderLines.value.$el.querySelector("tbody tr:first-of-type").focus();
    previouslyFocusedRow.value = tableOrderLines.value.$el.querySelector("tbody tr:first-of-type");
    selectedProductId.value = orderLines.value[0].product.id;
  }
};

watch(
  () => focusFirstOrderLine.value,
  (active) => {
    if (active === true) {
      timeout(100).then(() => {
        onFocusFirstOrderLine();
        focusFirstOrderLine.value = false;
      });
    }
  },
  { deep: true },
);

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const openProductInfoDialog = (productId: string) => {
  selectedRow.value.product.id = productId;
  showDialog.value = true;
};

onMounted(() => {
  document.addEventListener("keydown", handleKeyPress);
});

const handleKeyPress = (event: KeyboardEvent) => {
  const focusedElement = document.activeElement;
  const rows = tableOrderLines.value?.$el.querySelectorAll(".p-datatable tbody tr");
  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    if (rows) {
      rows.forEach((row: HTMLTableRowElement, index: number) => {
        if (row === focusedElement) {
          selectedRowIndex.value = index;
          setFocusedRow(index);
        }
      });
    }
  }

  const orderLine = orderLines.value[selectedRowIndex.value];
  if (event.key.toLowerCase() === "p") {
    if (rows) {
      rows.forEach((row: HTMLTableRowElement) => {
        if (row === focusedElement && orderLine) {
          openProductInfoDialog(orderLine.product.id);
        }
      });
    }
  }
  if (event.key === "Delete" && props.orderStatus === OrderStatus.Open) {
    if (rows) {
      rows.forEach((row: HTMLTableRowElement) => {
        if (row === focusedElement && orderLine) {
          onConfirmDelete(event, orderLine);
        }
      });
    }
  }
};

const openEllipsisContextMenu = (event: Event, data: OrderLine, index: number) => {
  selectedRow.value = data;
  selectedRowIndex.value = index;
  setFocusedRow(index);
  ellipsisContextMenuRef.value.openMenu(event);
};

const onRowContextMenu = (event: DataTableRowContextMenuEvent) => {
  selectedRow.value = event.data;
  selectedRowIndex.value = event.index;
  setFocusedRow(event.index);
  ellipsisContextMenuRef.value.openMenu(event.originalEvent);
};

const setFocusedRow = (index: number) => {
  previouslyFocusedRow.value = tableOrderLines.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
};

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value === false) {
      if (previouslyFocusedRow.value) {
        previouslyFocusedRow.value.focus();
      }
    }
  },
);
</script>

<style lang="scss" scoped>
:deep(.no-click) {
  pointer-events: none;
}

:deep(.c-line-done) {
  opacity: 0.6;
}

:deep(.no-click .clickable) {
  pointer-events: auto;
}

.material-symbols-outlined {
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 22;
}

:deep(.c-order-table).p-datatable .p-datatable-tbody > tr:focus {
  background-color: var(--p-blue-200); //--list-focus-bg: #CCE7F8;
  outline: none;
}

:deep(.p-inputnumber-input) {
  text-align: right;
}

:deep(.p-inputnumber.p-component.p-inputwrapper) {
  width: 100%;
}

:deep(.c-row-input.p-inputnumber-input) {
  --border-color: var(--p-blue-900); //--select-border: #003b66;
  font-size: 0.9rem;
  padding: 0.5rem;
  height: 1.8rem;
}

:deep(.p-datatable-header) {
  background: transparent;
  border: none;
}

.c-product-image {
  display: block;
  margin: auto;
  max-width: 2.3rem;
  max-height: 2.3rem;
}
.c-product-image-v {
  display: block;
  margin: auto;
  max-width: 3.3rem;
  max-height: 3.3rem;
}

.c-product-description {
  width: 15rem !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

:deep(.c-order-table).p-datatable .p-datatable-tbody > tr {
  .c-edit-row {
    min-width: 3.3rem;
  }

  .c-orderline-select {
    display: none;
    color: var(--p-blue-900); // --action-btn-bg: #013A64;
    text-align: center;
  }

  .c-orderline-edit {
    display: block;
    color: var(--p-blue-900); // --action-btn-bg: #013A64;
    text-align: center;
  }

  &:hover .c-orderline-select,
  &:focus .c-orderline-select {
    display: block;
    .p-button-icon {
      color: var(--p-blue-900); // --action-btn-bg: #013A64;
    }
    .c-delete-button .p-button-icon {
      color: var(--p-button-danger-background); //var(--delete-btn-bg);
    }
  }
}

.c-orderline-close-button {
  padding: 0;
  color: var(--p-blue-900); // --action-btn-bg: #013A64;
}

:deep(.c-order-table).p-datatable .p-datatable-tbody > tr.p-datatable-selectable-row > td {
  padding: 0 1rem;
}

:deep(.c-order-table).p-datatable tr.p-datatable-empty-message > td {
  display: none;
}
</style>
