<template>
  <div class="flex w-full justify-around mt-2">
    <div class="flex flex-col">
      <label for="weight">{{ t("common.total-weight") }}</label>
      <InputNumber id="weight" v-model="weightComputed" disabled input-class="w-24" />
    </div>
    <div class="flex flex-col">
      <label for="volume">{{ t("common.total-volume") }}</label>
      <InputNumber id="volume" v-model="volumeComputed" disabled input-class="w-24" />
    </div>
    <div class="flex flex-col">
      <label for="Shipping Cost">{{ t("common.shipping-cost") }}</label>
      <InputNumber
        id="shipping-cost"
        v-model="costComputed"
        mode="decimal"
        disabled
        input-class="w-24"
        :min="0"
        suffix=" kr"
        :allow-empty="false"
        :min-fraction-digits="2"
        :max-fraction-digits="2"
        data-testid="checkout-shipping-cost"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Parcel } from "@/models/bring/Parcel";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const costComputed = computed(() => props.cost);
const weightComputed = computed(() => props.weight);

const props = defineProps<{
  parcels: Parcel[];
  cost: number;
  weight: number;
}>();

const volumeComputed = computed(() => props.parcels.map((x) => calculateVolume(x)).reduce((a, b) => a + b));
const calculateVolume = (parcel: Parcel) => parcel.length * parcel.width * parcel.height;
</script>
