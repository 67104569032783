<template>
  <FloatLabel variant="on">
    <InputText
      id="shipping-price-panel"
      v-model="shippingPriceComputed"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('order.shipping-price').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      class="c-shipping-price-dropdown"
      :class="{ 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
      data-testId="shipping-price-panel"
      :pt:overlay:class="'hidden'"
      :fluid="true"
      :is-required="true"
      @click="showPanel"
    />
    <label for="shipping-price-panel">{{ t("order.shipping-price") }}</label>
  </FloatLabel>
  <ShippingPricePanel
    ref="shippingPricePanelRef"
    v-model:shipping-price="shippingPrice"
    v-model:agreed-freight="agreedFreight"
    :currency-iso="props.currencyIso"
    :freight-method="props.freightMethod"
    @shipping-calculation-dialog-visible="emit('shippingCalculationDialogVisible', $event)"
    @close-shipping-price-panel="focusInput = true"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import ShippingPricePanel from "./ShippingPricePanel.vue";
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderFreightMethod } from "@/models/order/OrderFreightMethod";

const { t } = useI18n();

const props = defineProps<{
  currencyIso: string;
  orderStatus?: OrderStatus;
  freightMethod: OrderFreightMethod;
}>();

const emit = defineEmits<{
  (e: "shippingCalculationDialogVisible", value: boolean): void;
}>();

const shippingPrice = defineModel<number>("shippingPrice", { required: true });
const agreedFreight = defineModel<boolean>("agreedFreight", { required: true });

const shippingPriceComputed = computed<string>({
  get: () =>
    shippingPrice.value.toString() === "0" ? t("order.shipping-price-empty") : shippingPrice.value.toString(),
  set: (value: string) => {
    const parsed = parseFloat(value);
    shippingPrice.value = isNaN(parsed) ? 0 : parsed;
  },
});

const focusInput = ref(false);

const shippingPricePanelRef = ref();
const showPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  focusInput.value = false;

  shippingPricePanelRef.value.toggle(event);
  event.preventDefault();
};

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>
<style scoped lang="scss">
.c-shipping-price-dropdown {
  width: 100%;
}
</style>
