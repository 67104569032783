import { type CountryInfo, useCountryApi } from "@multicase/portal-api/country";
import { type AuthHeaders } from "@multicase/portal-api";
import { i18n } from "@/locales/i18n";

export function useCountries() {
  const countries = ref<CountryInfo[]>([]);

  const { getRequest, error, loading } = useRequests();

  const countryApi = useCountryApi();

  const fetchCountries = async (): Promise<CountryInfo[]> => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      countries.value = await countryApi.getAll(authHeaders, i18n.global.locale.value);
    });
    return countries.value;
  };

  const fetchCityByPostalCode = async (code: string) => {
    return await getRequest(async (authHeaders: AuthHeaders): Promise<string> => {
      const postalInfo = await countryApi.getCitiesByPostalCode(authHeaders, code);
      if (postalInfo.length > 0) {
        return postalInfo[0].city;
      }
      return "";
    });
  };

  onMounted(fetchCountries);

  return { countries, fetchCityByPostalCode, fetchCountries, loading, error };
}
