<template>
  <div class="c-page-content h-auto !overflow-hidden">
    <div class="grid grid-cols-12 my-4 ml-4">
      <div class="c-transition" :class="showSidebar && !isMobile ? 'col-span-9 2xl:col-span-10' : 'col-span-12'">
        <Card>
          <template #content>
            <OrderSearchToolbar
              v-model:selected-icon="selectedIcon"
              @open-sort-list="openSortList"
              @refresh-list="onRefreshList"
              @open-column-chooser="openColumnChooser"
              @on-toggle-filter-sidebar="onToggleFilterSidebar"
            />

            <OrderList
              ref="orderListRef"
              v-model:filters="filtersComputed"
              :orders="orders"
              :loading="loading"
              :total-hits="totalHits"
              :page-size="pageSize"
              :page="page"
              :sort-field="sortField"
              :sort-order="sortOrder"
              @download-pdf="downloadPdf"
              @update:sort-order="onUpdateSortOrder"
              @update:sort-field="onUpdateSortField"
              @update:page="onUpdatePage"
              @update:page-size="onUpdatePageSize"
              @selected-icon="setSelectedIcon"
            />
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" :header="t('order.search.filter')" position="right">
          <OrderFilterSidebar v-model:filters="filtersComputed" />
        </Drawer>
      </div>

      <div
        v-else
        class="transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'col-span-3 2xl:col-span-2' : 'w-0 hidden'"
      >
        <Card class="ml-4 h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("order.search.filter") }}</span>
            </div>

            <OrderFilterSidebar v-model:filters="filtersComputed" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { useOrderSearchStore } from "@/stores/OrderSearchStore";
import { SearchOrder } from "@/models/search/order/SearchOrder";
import { OrderSearchRequest } from "@/models/search/order/OrderSearchRequest";
import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import OrderFilterSidebar from "../components/search/OrderFilterSidebar.vue";
import OrderSearchToolbar from "@/components/search/OrderSearchToolbar.vue";
import OrderList from "../components/OrderList.vue";
import { useDocument } from "@/api/document/DocumentService";
import { useMobile } from "@/utils/MobileService";
import { useAuth } from "@cumulus/event-bus";

const { t } = useI18n();
const { getUser } = useAuth();

const { orderSearch } = useOrderSearchStore();
const route = useRoute();

const pageSize = ref<number>(50);
const page = ref(1);
const totalHits = ref(0);
const query = ref("");
const loading = ref(false);
const orders = ref<SearchOrder[]>([]);
const filters = ref<OrderSearchFilters>(new OrderSearchFilters());
const showSidebar = ref(false);
const sortOrder = ref(-1);
const sortField = ref("");

const selectedIcon = ref("");
const setSelectedIcon = (value: string) => {
  selectedIcon.value = value;
};

const { isMobile } = useMobile();
watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

const filtersComputed = computed<OrderSearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: OrderSearchFilters) => {
  filters.value = value;
  loading.value = true;
  page.value = 1;
  await search();
};

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = decodeURIComponent((newQuery as string) ?? "");
      await search();
    }
  },
);

let identityId = "";

onMounted(async () => {
  identityId = (await getUser()).getEmployee()?.identityId ?? "";
  const active = localStorage.getItem(`user-filter-ordersearch-${identityId}`);
  if (active !== null) {
    showSidebar.value = JSON.parse(active);
  }

  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await search();
});

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
  localStorage.setItem(`user-filter-ordersearch-${identityId}`, JSON.stringify(showSidebar.value));
};

const search = async () => {
  try {
    const request = new OrderSearchRequest(query.value);
    request.page = page.value;
    request.pageSize = pageSize.value;
    request.filters = filters.value;
    sortOrder.value = sortField.value === "" ? -1 : sortOrder.value;
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";

    const response = await orderSearch(request);
    if (response.orders.length === 0) {
      orders.value = [];
      totalHits.value = 0;
      return;
    }

    orders.value = response.orders;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const { getOrderConfirmationUrl } = useDocument();
const toast = useCumulusToast(useToast());

const downloadPdf = async (id: string) => {
  const url = await getOrderConfirmationUrl(id);

  if (url && url.length > 0) {
    window.open(url, "_blank");
  } else {
    toast.add({
      severity: "error",
      summary: t("common.error"),
      detail: t("order.download-link-missing"),
    });
  }
};

const orderListRef = ref();
const openColumnChooser = (event: Event) => {
  orderListRef.value.openColumnChooser(event);
};

const openSortList = (event: Event) => {
  orderListRef.value.openSortList(event);
};
</script>

<style scoped lang="scss">
.c-transition {
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
</style>
