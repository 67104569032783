<template>
  <div class="flex items-left gap-2 mt-3">
    <FilterOverview v-model:filters="filters" />
  </div>
  <div>
    <DataTable
      ref="orderSearchResult"
      :key="renderKey as unknown as number"
      v-model:context-menu-selection="selectedOrder"
      v-model:selection="selectedOrder"
      :value="orders"
      data-key="id"
      :auto-layout="true"
      responsive-layout="scroll"
      selection-mode="single"
      :sort-order="sortOrder"
      :sort-field="sortField"
      class="c-datatable c-order-search-table"
      :loading="loading"
      :striped-rows="true"
      removable-sort
      :paginator="true"
      :rows="pageSize"
      :lazy="true"
      :total-records="totalHits"
      :rows-per-page-options="[50, 100]"
      :current-page-report-template="
        t('common.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      tabindex="0"
      data-testid="order-search-result"
      :row-class="addGlobalSearchClass"
      scrollable
      scroll-height="75vh"
      :resizable-columns="true"
      column-resize-mode="fit"
      :reorderable-columns="true"
      @row-select="onRowSelected"
      @row-dblclick="onRowDblClick"
      @sort="onSort"
      @page="onPage"
      @row-contextmenu="onRowContextMenu"
      @column-reorder="onColumnReorder"
      @column-resize-end="onColumnResizeEnd"
    >
      <Column
        v-for="col of selectedColumnsComputed as unknown as DataTableColumn[]"
        :key="col.field"
        :field="col.field"
        :header="t(col.header)"
        :column-key="col.field"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
            class: col.field,
          },
          columntitle: {
            class: {
              hidden: col.field === OrderSearchColumns.EditHandler,
            },
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <div v-if="col.field === OrderSearchColumns.EditHandler" v-tooltip.bottom="doubleClickTooltip">
            <div class="c-ordersearch-select">
              <div class="flex justify-center items-center">
                <i
                  role="button"
                  class="pi pi-pencil c-context-menu-button"
                  :data-testid="`context-menu-button-${index}`"
                  @click="openSearchOrder(data)"
                >
                </i>
              </div>
            </div>
          </div>
          <div v-else>
            <template v-if="col.field === OrderSearchColumns.Registered">
              <span>{{ d(data.registered, "long") }}</span>
            </template>
            <template v-else-if="col.field === OrderSearchColumns.OrderNumber">
              <div class="flex justify-content-between align-items-center">
                <span
                  :data-testId="`order-number-text-${index}`"
                  class="c-order-number-text"
                  @click="onOrderNumberClicked(data)"
                  >{{ data.orderNumber }}</span
                >
              </div>
            </template>
            <template v-else-if="col.field === OrderSearchColumns.OrderReference">
              {{ data.orderReference }}
            </template>
            <template v-else-if="col.field === OrderSearchColumns.CustomerName">
              {{ data.customer.customerName }}
            </template>
            <template v-else-if="col.field === OrderSearchColumns.CustomerNumber">
              <div class="flex justify-content-between align-items-center">
                <span
                  v-tooltip.bottom="clickCustomerNumberTooltip"
                  :data-testId="`customer-number-text-${index}`"
                  class="c-customer-number-text"
                  @click="onCustomerNumberClicked(data)"
                  >{{ data.customer.customerNumber }}</span
                >
              </div>
            </template>
            <template v-else-if="col.field === OrderSearchColumns.OrderStatus">
              {{ t(`order.status.${data.orderStatus.toLowerCase()}`) }}
            </template>
            <template v-else-if="col.field === OrderSearchColumns.PDF">
              <img
                v-tooltip.bottom="downloadPDFTooltip"
                :data-testid="'btn-show-pdf-' + index"
                :src="pdfIcon"
                @click="$emit('downloadPdf', data.id)"
              />
            </template>
            <template v-else>
              {{ data[field as keyof typeof data] }}
            </template>
          </div>
        </template>
      </Column>

      <template v-if="!loading" #empty>
        <span>{{ t("order.search.total-hits", 0) }}</span>
      </template>
    </DataTable>

    <PopOverSorting
      ref="op"
      v-model:selected-icon="selectedIcon"
      :search-columns="filteredSortedColumns"
      :default-sort-field="defaultSortField"
      @on-selected-row="onSelectedRow"
    />

    <PopOverColumnChooser
      ref="chooserRef"
      v-model:selected-columns="selectedColumnsComputed"
      :columns="filteredColumns"
      :label="t('common.reset')"
      @reset-columns="resetColumns"
    />
  </div>
  <ContextMenu ref="cm" :model="menuModel" data-testid="order-context-menu" />
  <PrintDialog v-if="showPrintModal" v-model:visible-dialog="showPrintModal" :order="selectedOrder" />
</template>
<script setup lang="ts">
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { SearchOrder } from "@/models/search/order/SearchOrder";
import type {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { computed, nextTick, ref, watch } from "vue";

import PrintDialog from "./search/PrintDialog.vue";
import { NIL as emptyUuid } from "uuid";
import { useI18n } from "vue-i18n";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import { OrderSearchColumns } from "@/models/order/OrderSearchColumns";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import FilterOverview from "@/components/search/FilterOverview.vue";
import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import { PopOverSorting } from "@cumulus/components";
import { PopOverColumnChooser } from "@cumulus/components";

const { t, d } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useCumulusToast(useToast());
const orderSearchResult = ref();
const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);
const cm = ref();
const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};
const showPrintModal = ref(false);

const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};
const selectedOrder = ref<SearchOrder>(new SearchOrder());
const props = defineProps<{
  orders: SearchOrder[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const filters = defineModel<OrderSearchFilters>("filters", { default: () => new OrderSearchFilters() });

const emit = defineEmits<{
  (e: "downloadPdf", value: string): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "selectedIcon", value: string): void;
}>();

const chooserRef = ref();
const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const op = ref();
const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const orderSearchColumns = [
  { field: "registered", header: "order.search.registered", sortable: true },
  { field: "ordernumber", header: "order.order-number.label", sortable: true },
  { field: "orderReference", header: "order.search.order-reference", sortable: true },
  {
    field: "customer.customerName",
    header: "order.search.customer-name",
    sortable: true,
  },
  {
    field: "customer.customerNumber",
    header: "order.search.customer-number",
    sortable: true,
  },
  { field: "orderStatus", header: "order.search.order-status", sortable: true },

  { field: "pdf", header: "order.pdf", class: "c-pdf-icon text-center", sortable: false },
  { field: "editHandler", header: "order.properties.edit-handle", class: "c-edit-handler", sortable: false },
] as DataTableColumn[];

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("orderSearch", orderSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const filteredSortedColumns = computed(() => {
  return orderedColumns.value.filter((col) => col.field !== OrderSearchColumns.EditHandler);
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(orderSearchColumns.find((column) => column.field === OrderSearchColumns.Registered));
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const selectedIcon = computed(() => {
  const icon = sortBy[currentIconIndex.value].icon;
  emit("selectedIcon", icon);
  return icon;
});

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    defaultSortOrder.value = event.sortOrder === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = orderSearchColumns.find((column) => column.field === event.sortField);

    emit("update:sortOrder", event.sortOrder ?? 0);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const addGlobalSearchClass = (data: SearchOrder) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedOrder.value?.id === data?.id },
  ];
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchOrder(event.data as SearchOrder);
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  openSearchOrder(event.data as SearchOrder);
};

watch([() => props.orders, () => props.loading], ([orders, loading]) => {
  if (loading === false && orders.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const doubleClickTooltip = computed(() => {
  return {
    value: `Edit order (dobbelt click/enter on row)`,
    showDelay: 1000,
    hideDelay: 100,
    pt: {
      root: {
        class: `!max-w-fit`,
      },
      text: {
        class: `w-full !text-black !bg-emphasis p-3 text-xs border-solid border-2 border-white`,
      },
      arrow: {
        style: `border-bottom-color: #f0f6fa;`,
      },
    },
  };
});

const clickCustomerNumberTooltip = computed(() => {
  return {
    value: `Click to open customer`,
    showDelay: 1000,
    hideDelay: 100,
    pt: {
      root: {
        class: `!max-w-fit`,
      },
      text: {
        class: `w-full !text-black !bg-emphasis p-3 text-xs border-solid border-2 border-white`,
      },
      arrow: {
        style: `border-bottom-color: #f0f6fa;`,
      },
    },
  };
});

const downloadPDFTooltip = computed(() => {
  return {
    value: `Download PDF`,
    showDelay: 1000,
    hideDelay: 100,
    pt: {
      root: {
        class: `!max-w-fit`,
      },
      text: {
        class: `w-full !text-black !bg-emphasis p-3 text-xs border-solid border-2 border-white`,
      },
      arrow: {
        style: `border-bottom-color: #f0f6fa;`,
      },
    },
  };
});

const openSearchOrder = (searchOrder: SearchOrder) => {
  searchOrder != null && searchOrder.id !== emptyUuid
    ? router.push({ name: "order-edit", params: { id: searchOrder.id } })
    : toast.add({
        severity: "warn",
        summary: t("order.search.toast.open-order-failed"),
        detail: t("order.search.toast.no-order-selected"),
      });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const focusSearchResult = (id = "") => {
  if (props.orders.length > 0 && orderSearchResult.value) {
    const element = id === "" ? null : orderSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    orderSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onOrderNumberClicked = (order: SearchOrder) => {
  selectedOrder.value = order;
  openSearchOrder(order);
};

const onCustomerNumberClicked = (order: SearchOrder) => {
  selectedOrder.value = order;
  if (order.customer.customerNumber != null) {
    openSearchCustomer(order);
  }
};

const openSearchCustomer = (searchCustomer: SearchOrder) => {
  searchCustomer.customer != null && searchCustomer.customer.id !== emptyUuid
    ? router.push({ path: `/customer/edit/${searchCustomer.customer.id}` })
    : toast.add({
        severity: "warn",
        summary: t("order.document.open-customer-failed"),
        detail: t("order.document.no-customer-selected"),
      });
};

defineExpose({
  openColumnChooser,
  openSortList,
});
</script>
<style scoped lang="scss">
.c-order-number-text:hover {
  text-decoration: underline;
}

.c-customer-number-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
  @media (max-width: 992px) {
    .p-paginator-first {
      margin: 0;
    }
    .p-paginator-current {
      display: none;
    }
  }
}
.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}
:deep(.p-datatable .p-datatable-thead > tr > th.c-pdf-icon) {
  .p-column-title {
    visibility: hidden;
  }
}
:deep(.p-multiselect) {
  border: none;
}

:deep(.p-datatable .p-datatable-tbody > tr) {
  .c-edit-handler {
    max-width: 2rem;
  }
  .c-ordersearch-select {
    display: none;
  }

  &:hover .c-ordersearch-select,
  &:focus .c-ordersearch-select {
    display: block;
    .p-button-icon {
      color: var(--p-blue-900);
    }
  }
}
</style>
