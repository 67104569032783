<template>
  <h4 class="text-center">{{ t("order.search.filter-order-date") }}</h4>

  <div>
    <div class="col-span-12 my-4">
      <CumulusDatePicker
        id="from-date-filter"
        v-model:date="fromDateComputed"
        :label="t('order.search.filter-date-from', { symbol: ':' })"
        data-test-id="from-date-filter"
        :debounce-time="800"
      />
    </div>
    <div class="col-span-12 mb-4">
      <CumulusDatePicker
        id="to-date-filter"
        v-model:date="toDateComputed"
        :label="t('order.search.filter-date-to', { symbol: ':' })"
        data-test-id="to-date-filter"
        :debounce-time="800"
      />
    </div>
  </div>

  <Button
    :label="t('order.search.filter-last-ten-days')"
    severity="secondary"
    variant="outlined"
    class="w-full dark:border-surface-600 border"
    data-testid="last-10-days-filter-btn"
    @click="setDateFilterLastDays(10)"
  />

  <hr class="my-6" />

  <h4 class="text-center">{{ t("order.search.filter-status") }}</h4>
  <div
    v-for="(value, index) in allStatusesComputed"
    :key="index"
    class="flex justify-between relative items-center p-2 rounded-md mt-1"
    :class="{
      'bg-[#E7F0F7] dark:bg-slate-800': index % 2 === 0,
      'bg-[#F7FAFC] dark:bg-slate-600': index % 2 === 1,
    }"
  >
    <label :for="'order-status-' + value.status.toLowerCase()">{{
      t(`order.status.${value.status.toLowerCase()}`)
    }}</label>
    <div class="ml-auto">
      <Checkbox
        v-model="statusesComputed"
        :value="value.status"
        :input-id="'order-status-' + value.status.toLowerCase()"
        :data-testid="'order-status-' + value.status.toLowerCase()"
      />
    </div>
  </div>

  <hr class="my-6" />

  <h4 class="text-center">
    <label for="customer-number-filter"> {{ t("order.customer.label") }} </label>
  </h4>

  <AutoComplete
    v-model="customerNumbersComputed"
    input-id="customer-number-filter"
    data-testid="customer-number-filter"
    :placeholder="props.filters.customerNumbers.length > 0 ? '' : t('order.search.filter-customer-number')"
    multiple
    fluid
    :typeahead="false"
  />

  <AutoComplete
    v-model="customerNamesComputed"
    class="mt-2"
    input-id="customer-name-filter"
    data-testid="customer-name-filter"
    :placeholder="props.filters.customerNames.length > 0 ? '' : t('order.customer-name')"
    multiple
    fluid
    :typeahead="false"
  />

  <hr class="my-6" />

  <h4 class="text-center">
    <label for="product-number-filter">
      {{ t("order.product.product") }}
    </label>
  </h4>

  <AutoComplete
    v-model="productNumbersComputed"
    data-testid="product-number-filter"
    input-id="product-number-filter"
    :placeholder="props.filters.productNumbers.length > 0 ? '' : t('order.properties.product-number')"
    multiple
    fluid
    :typeahead="false"
  />

  <AutoComplete
    v-model="productNamesComputed"
    class="mt-2"
    data-testid="product-name-filter"
    input-id="product-name-filter"
    :placeholder="props.filters.productNames.length > 0 ? '' : t('order.properties.product-name')"
    multiple
    fluid
    :typeahead="false"
  />

  <hr class="my-6" />

  <h4 class="text-center">
    <label for="product-number-filter"> {{ t("order.header") }} </label>
  </h4>

  <AutoComplete
    v-model="orderReferencesComputed"
    class="mt-2"
    data-testid="order-ref-filter"
    input-id="order-ref-filter"
    :placeholder="props.filters.orderReferences.length > 0 ? '' : t('order.order-reference.label')"
    multiple
    fluid
    :typeahead="false"
  />
</template>

<script setup lang="ts">
import { OrderStatus } from "@/models/order/OrderStatus";
import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusDatePicker } from "@cumulus/components";

const { t } = useI18n();

const props = defineProps<{
  filters: OrderSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: OrderSearchFilters): void;
}>();

const allStatusesComputed = computed(() => {
  const allStatuses = Object.values(OrderStatus);
  return allStatuses.map((status, index) => {
    return {
      status: status,
      index: index,
    };
  });
});

const statusesComputed = computed<OrderStatus[]>({
  get: () => props.filters.orderStatuses,
  set: (value) => {
    const filters = { ...props.filters, orderStatuses: value };
    emit("update:filters", filters);
  },
});

const fromDateComputed = computed<string>({
  get: () => {
    return props.filters.fromDate ? props.filters.fromDate : "";
  },
  set: (fromDate) => {
    if (props.filters.fromDate === fromDate) return;

    const filters = { ...props.filters, fromDate: fromDate };
    emit("update:filters", filters);
  },
});

const toDateComputed = computed<string>({
  get: () => {
    return props.filters.toDate ? props.filters.toDate : "";
  },
  set: (toDate) => {
    toDate = toDate.replace("T00:00:00.000Z", "T23:59:59.999Z");
    if (props.filters.toDate === toDate) return;

    const filters = { ...props.filters, toDate: toDate };
    emit("update:filters", filters);
  },
});

const setDateFilterLastDays = (days: number) => {
  const toDate = new Date();
  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - days);

  const filters = {
    ...props.filters,
    toDate: toDate.toJSON(),
    fromDate: fromDate.toJSON(),
  };
  emit("update:filters", filters);
};

const customerNumbersComputed = computed<string[]>({
  get: () => props.filters.customerNumbers,
  set: (value) => {
    const filters = { ...props.filters, customerNumbers: value };
    emit("update:filters", filters);
  },
});

const customerNamesComputed = computed<string[]>({
  get: () => props.filters.customerNames,
  set: (value) => {
    const filters = { ...props.filters, customerNames: value };
    emit("update:filters", filters);
  },
});

const productNumbersComputed = computed<string[]>({
  get: () => props.filters.productNumbers,
  set: (value) => {
    const filters = { ...props.filters, productNumbers: value };
    emit("update:filters", filters);
  },
});

const productNamesComputed = computed<string[]>({
  get: () => props.filters.productNames,
  set: (value) => {
    const filters = { ...props.filters, productNames: value };
    emit("update:filters", filters);
  },
});

const orderReferencesComputed = computed<string[]>({
  get: () => props.filters.orderReferences,
  set: (value) => {
    const filters = { ...props.filters, orderReferences: value };
    emit("update:filters", filters);
  },
});
</script>
