<template>
  <div class="c-chips">
    <ul v-if="hasActiveFilters" class="c-chips-multiple-container">
      <li>
        <Button
          type="button"
          :label="t('order.search.clear-filter')"
          icon="pi pi-times-circle"
          class="text-sm mr-4 p-1.5"
          data-testid="clear-filter-btn"
          rounded
          @click="clearFilters"
        />
      </li>
      <FilterItem
        v-if="filters.fromDate !== null"
        filter-type="fromDate"
        :values="d(filters.fromDate)"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.toDate !== null"
        filter-type="toDate"
        :values="d(filters.toDate)"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.orderStatuses.length > 0"
        :values="filters.orderStatuses.map((status) => t(`order.status.${status.toLowerCase()}`))"
        filter-type="orderStatuses"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.productNumbers.length > 0"
        :values="filters.productNumbers"
        filter-type="productNumbers"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.productNames.length > 0"
        :values="filters.productNames"
        filter-type="productNames"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.customerNumbers.length > 0"
        :values="filters.customerNumbers"
        filter-type="customerNumbers"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.customerNames.length > 0"
        :values="filters.customerNames"
        filter-type="customerNames"
        @remove-filter="removeFilter"
      />
      <FilterItem
        v-if="filters.orderReferences.length > 0"
        :values="filters.orderReferences"
        filter-type="orderReferences"
        @remove-filter="removeFilter"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { OrderSearchFilters } from "@/models/search/order/OrderSearchFilters";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import FilterItem from "./FilterItem.vue";

const { t, d } = useI18n();

const props = defineProps<{
  filters: OrderSearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: OrderSearchFilters): void;
}>();

const clearFilters = () => {
  const filters = new OrderSearchFilters();
  emit("update:filters", filters);
};

const removeFilter = (filterType: keyof OrderSearchFilters | string, index: number) => {
  const filters = { ...props.filters };
  const filterToRemove = filters[filterType as keyof OrderSearchFilters];

  if (Array.isArray(filterToRemove)) {
    filterToRemove.splice(index, 1);
  } else if (filterType === "fromDate" || filterType === "toDate") {
    filters[filterType as "fromDate" | "toDate"] = null;
  }

  emit("update:filters", filters);
};

const hasActiveFilters = computed<boolean>(() => {
  return (
    props.filters.orderStatuses.length > 0 ||
    props.filters.productNumbers.length > 0 ||
    props.filters.productNames.length > 0 ||
    props.filters.customerNumbers.length > 0 ||
    props.filters.customerNames.length > 0 ||
    props.filters.orderReferences.length > 0 ||
    props.filters.fromDate !== null ||
    props.filters.toDate !== null
  );
});
</script>
<style scoped lang="scss">
.c-chips {
  display: inline-flex;
}

.c-chips-multiple-container {
  padding: 0.25rem 0.5rem;
  margin: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
