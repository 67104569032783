import { httpClient, type AuthHeaders } from "@multicase/portal-api";
import { NewImage } from "@/models/image/NewImage";
import { ImageInfo } from "@/models/image/ImageInfo";

class ImageApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_IMAGE != "undefined" && import.meta.env.VITE_APP_API_IMAGE
        ? import.meta.env.VITE_APP_API_IMAGE + "/images"
        : `${import.meta.env.VITE_APP_API_URL as string}/images`;
  }

  public async upload(authHeaders: AuthHeaders, product: NewImage): Promise<ImageInfo> {
    return await httpClient(this.uri, authHeaders)
      .post("/", product)
      .then(({ data }) => data);
  }
}

const imageApi = new ImageApi();
export { imageApi };
