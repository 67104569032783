<template>
  <DataTable
    :value="accessoryProducts"
    :loading="loadingAccessories"
    data-testid="accessories-table"
    class="c-datatable"
  >
    <Column field="productNumber" :header="t('order.properties.product-number')" />
    <Column field="name" :header="t('order.properties.product-name')" />
    <Column :header="t('order.properties.quantity')">
      <template #body="{ data, index }">
        <InputNumber
          input-class="w-20"
          :model-value="productQuantities.get(data)"
          :allow-empty="false"
          :input-id="`quantityInput-${index}`"
          :pt="{
            hooks: { onMounted: focusFirstInput },
          }"
          @update:model-value="onQuantityChanged($event, data)"
        />
      </template>
    </Column>
    <Column field="availability" :header="t('order.complementary-items.header.available')">
      <template #body="{ data }">
        <div class="text-right">
          {{ data.availability?.length > 0 ? data.availability[0].quantityAvailableFromStock : 0 }}
        </div>
      </template>
    </Column>
    <Column field="costPrice" :header="t('order.complementary-items.header.cost-price')">
      <template #body="{ data }">
        <div class="text-right">
          {{
            bankersRound(
              data.supplierPrices?.find((s: SearchProductSupplier) => s.isDefaultSupplier)?.replacementCost,
            ).toFixed(2) ?? 0
          }}
        </div>
      </template>
    </Column>
    <Column field="contributionMargin" :header="t('order.complementary-items.header.contribution-margin')">
      <template #body="{ data }">
        <div :class="isNegativeNumber(data.prices[0].contributionMargin) ? 'c-negative-number' : '' + 'text-right'">
          {{ data.prices?.length > 0 ? bankersRound(data.prices[0].contributionMargin).toFixed(2) : "" }}
        </div>
      </template>
    </Column>
    <Column :header="t('order.complementary-items.header.price-excl-vat')" header-class="c-header">
      <template #body="{ data }">
        <div class="text-right">
          {{ data.prices?.length > 0 ? bankersRound(data.prices[0].listPrice).toFixed(2) : "" }}
        </div>
      </template>
    </Column>
    <Column :header="t('order.complementary-items.header.price-incl-vat')" header-class="c-header">
      <template #body="{ data }">
        <div class="text-right">
          {{ data.prices?.length > 0 ? bankersRound(data.prices[0].listPriceIncVat).toFixed(2) : "" }}
        </div>
      </template>
    </Column>
  </DataTable>
  <div class="flex justify-end mt-4 mr-8 mb-4">
    <Button :label="t('common.cancel')" class="p-button p-button-rounded mr-6" @click="onCancel" />
    <Button
      :label="t('order.complementary-items.buttons.add')"
      class="p-button p-button-rounded"
      severity="success"
      @click="onAddProducts"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { SearchProductSupplier } from "@/models/search/product/SearchProductSupplier";
import { nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ProductSearchByIdsRequest } from "@/models/search/product/ProductSearchByIdsRequest";
import { useProductSearchService } from "@/api/search/ProductSearchService";
import { useDiscountService } from "@/api/discount/DiscountService";
import { ProductPricesRequest } from "@/models/discount/ProductPricesRequest";
import { useAccessoryStore } from "@/stores/AccessoryStore";
import type { SearchProductViewModel } from "@/models/search/product/view-model/SearchProductViewModel";

const { t } = useI18n();
const loadingAccessories = ref(true);
const { productSearchByIds } = useProductSearchService();
const accessoryStore = useAccessoryStore();
const { accessoryProductIds } = storeToRefs(accessoryStore);

//TODO: Should be moved to a utils package eventually
const bankersRound = (n: number, d = 2) => {
  const x = n * Math.pow(10, d);
  const r = Math.round(x);
  const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return br / Math.pow(10, d);
};

const accessoryProducts = ref<SearchProductViewModel[]>([]);

const isNegativeNumber = (value: number) => {
  return value < 0;
};

const focusFirstInput = () => {
  nextTick(() => {
    document.getElementById("quantityInput-0")?.focus();
  });
};

const emit = defineEmits<{
  (event: "cancel"): void;
  (event: "addProducts", value: Map<SearchProductViewModel, number>): void;
}>();

const onCancel = () => {
  productQuantities.value.clear();
  emit("cancel");
};

const onQuantityChanged = (event: number, product: SearchProductViewModel) => {
  if (event == 0) {
    if (productQuantities.value.has(product)) {
      productQuantities.value.delete(product);
    }
    return;
  }
  productQuantities.value.set(product, event);
};

const productQuantities = ref<Map<SearchProductViewModel, number>>(new Map());

const onAddProducts = () => {
  emit("addProducts", productQuantities.value);
  productQuantities.value.clear();
};

const { getProductPrices } = useDiscountService();

onMounted(async () => {
  const result = await productSearchByIds(new ProductSearchByIdsRequest(accessoryProductIds.value ?? []));
  const products = result.products;

  const productIds = result.products.map((p) => p.id);

  const productPricesResult = await getProductPrices(new ProductPricesRequest(productIds, null));

  products.forEach((product) => {
    const productPrices = productPricesResult.find((p) => p.productId === product.id);
    if (productPrices) {
      const mainSupplier = product.supplierPrices.find((s) => s.isDefaultSupplier === true);
      if (mainSupplier) {
        mainSupplier.replacementCost = productPrices.costPrice;
      }
      const price = product.prices[0];
      if (price) {
        price.listPrice = productPrices.listPrice;
        price.listPriceIncVat = productPrices.listPriceIncVat;
        price.contributionMargin = productPrices.contributionMargin;
      }
    }
  });

  accessoryProducts.value = products;
  loadingAccessories.value = false;
});
</script>
<style>
.c-negative-number {
  color: var(--negative-number);
}
.c-header {
  min-width: 90px;
}
</style>
