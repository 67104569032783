import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { type AuthHeaders } from "@multicase/portal-api";

export function useRequests() {
  const loading = ref<boolean>(false);
  const saving = ref<boolean>(false);
  const error = ref<unknown | null>(null);

  const { getAuthHeaders, instance } = useAuth();

  const getRequest = async <T>(fn: (authHeaders: AuthHeaders) => Promise<T>): Promise<T | undefined> => {
    loading.value = true;
    error.value = null;

    try {
      const authHeaders = await getAuthHeaders();
      return fn(authHeaders);
    } catch (e) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
        return;
      }

      error.value = e;
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const mutationRequest = async (fn: (authHeaders: AuthHeaders) => Promise<void>) => {
    saving.value = true;
    error.value = null;

    try {
      const authHeaders = await getAuthHeaders();
      return fn(authHeaders);
    } catch (e) {
      if (error instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(loginRequest);
        return;
      }

      error.value = e;
      console.error(e);
    } finally {
      saving.value = false;
    }
  };

  return {
    loading,
    saving,
    error,
    getRequest,
    mutationRequest,
  };
}
