<template>
  <Dialog
    v-model:visible="visible"
    :header="t('order.product.info-dialog-header')"
    :modal="true"
    class="c-product-info"
    data-testid="product-info-dialog"
    :loading="loadingProduct"
    @keydown.esc.stop="onCancel"
  >
    <div class="c-product-info-dialog">
      <div class="flex flex-col">
        <div class="col-span-12 p-0">
          <div class="c-fields-container">
            <div class="flex flex-col md:flex-row mt-4">
              <div class="col-span-12 md:col-span-6">
                <FloatLabelInput
                  id="product-number"
                  :label="t('order.product.number')"
                  data-test-id="product-number"
                  :disabled="true"
                  :value="product.productNumber"
                />
              </div>
              <div class="col-span-12 md:col-span-6">
                <FloatLabelInput
                  :label="t('order.product.gtin')"
                  data-test-id="product-gti-number"
                  :value="product.gtin"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="col-span-12">
              <FloatLabelInput
                id="product-name"
                :label="t('order.product.name')"
                data-test-id="product-name"
                :value="product.name"
                :disabled="true"
              />
              <div class="mt-6">
                <FloatLabelInput
                  :label="t(`order.product.description`)"
                  data-test-id="product-description"
                  :value="truncatedDescription"
                  :disabled="true"
                  :title="product.description"
                />
              </div>
              <div class="mt-6">
                <FloatLabelInput
                  :label="t(`order.product.group-level`)"
                  data-test-id="product-group"
                  :disabled="true"
                  :value="productHierarchy?.name"
                />
              </div>
              <div class="mt-6">
                <FloatLabelInput
                  :label="t('order.product.manufacturer')"
                  data-test-id="product-manufacturer"
                  :value="manufacturer.name"
                  :disabled="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row p-0 mt-6">
          <div class="col-span-12 md:col-span-6 p-0">
            <div class="c-fields-container h-full">
              <ProductDimensions :dimension="product?.dimension" :weight="product?.weight" />
            </div>
          </div>
          <div class="col-span-12 md:col-span-6 p-0 pt-6 md:pt-0 md:pl-6">
            <div class="c-fields-container">
              <template v-if="product.productImages && product.productImages.length > 0">
                <Galleria
                  ref="galleria"
                  v-model:active-index="activeIndex"
                  :value="product.productImages"
                  :container-style="{ 'max-width': '100%', height: '100%' }"
                  :show-thumbnails="true"
                  :num-visible="2"
                  :circular="true"
                  :transition-interval="3000"
                  :pt="{
                    content: {
                      class: 'relative',
                      style: { width: '100%' },
                    },
                    previousThumbnailButton: { tabindex: '-1' },
                    nextThumbnailButton: { tabindex: '-1' },
                  }"
                >
                  <template #item="slotProps">
                    <img
                      :src="resizeImage(slotProps.item.url, 200, 150)"
                      :alt="slotProps.item.name"
                      class="c-product-image"
                      :style="[
                        {
                          borderTopLeftRadius: '12px',
                          borderTopRightRadius: '12px',
                        },
                      ]"
                      @click="openEnlargedImage"
                    />
                  </template>
                  <template #thumbnail="slotProps">
                    <div class="grid grid-cols-12 gap-4 grid-nogutter">
                      <img
                        :src="resizeImage(slotProps.item.url, 50, 50)"
                        :alt="slotProps.item.name"
                        style="height: 50px; width: 50px; display: block"
                      />
                    </div>
                  </template>
                </Galleria>
              </template>
              <template v-else>
                <p class="text-center">{{ t("order.product.no-image") }}</p>
              </template>
            </div>
          </div>
        </div>
        <div
          v-if="product.productInfo !== ''"
          class="c-fields-container col-span-12 p-4 flex flex-col mt-6 c-product-info-container"
        >
          <span class="text-center"> {{ t("order.product.more-info") }}</span>
          <QuillEditor v-model="product.productInfo" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row-reverse justify-between">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            data-testid="delivery-dialog-cancel-btn"
            class="c-dialog-default-button"
            tabindex="1"
            autofocus
            @click="onCancel"
          />
        </div>
      </div>
    </template>
  </Dialog>
  <Dialog
    v-if="product.productImages.length > 0"
    v-model:visible="imageEnlarged"
    content-class="h-full"
    modal
    @hide="closeEnlargedImage"
    @keydown.esc.stop="imageEnlarged = false"
  >
    <div v-for="(image, index) of product.productImages" :key="index">
      <img :src="resizeImage(image.url, 500, 500)" :alt="image.name" class="p-overlay-badge c-image" />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ProductDimensions from "./ProductDimensions.vue";
import { useImageService } from "@/api/image/ImageService";
import Galleria from "primevue/galleria";
import { useProduct } from "@/api/product/ProductService";
import { Product } from "@/models/product/Product";
import { Manufacturer } from "@multicase/portal-api/manufacturer";
import { useProductHierarchy } from "@/api/product-hierarchy/ProductHierarchyService";
import { LanguageIsoType } from "@/models/product/LanguageIsoType";
import { ProductHierarchyByName } from "@/models/product/ProductHierarchyByName";
import QuillEditor from "./QuillEditor.vue";

const { resizeImage } = useImageService();
const { t } = useI18n();
const { getProductById } = useProduct();
const { fetchManufacturerById } = useManufacturers();
const { getProductHierarchy } = useProductHierarchy();

const activeIndex = ref(0);
const product = ref<Product>(new Product());
const loadingProduct = ref(false);
const manufacturer = ref<Manufacturer>(new Manufacturer());
const productHierarchy = ref<ProductHierarchyByName | null>(null);
const imageEnlarged = ref(false);

const props = defineProps<{
  showDialog: boolean;
  productId: string | null;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const onCancel = () => {
  visible.value = false;
};
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

onMounted(async () => {
  if (props.productId !== "" && props.productId !== null) {
    loadingProduct.value = true;
    try {
      product.value = await getProductById(props.productId);
      if (product.value.manufacturerId !== "") {
        manufacturer.value = await fetchManufacturerById(product.value.manufacturerId);
      }
      if (product.value.productHierarchyId !== "") {
        const result = await getProductHierarchy(product.value.productHierarchyId);
        if (!result) {
          productHierarchy.value = null;
          return;
        }
        productHierarchy.value = ProductHierarchyByName.create(result, languageIso.value);
      }
    } finally {
      loadingProduct.value = false;
    }
  }
});

const closeEnlargedImage = () => {
  imageEnlarged.value = false;
};

const openEnlargedImage = () => {
  imageEnlarged.value = true;
};

const maxDescriptionLength = 50;

const truncatedDescription = computed(() => {
  if (product.value.description.length > maxDescriptionLength) {
    return product.value.description.slice(0, maxDescriptionLength) + "...";
  }
  return product.value.description;
});
</script>

<style lang="scss" scoped>
.c-fields-container {
  border: var(--field-group-border);
  border-radius: var(--field-group-border-radius);
}
:deep(.p-galleria .p-galleria-thumbnail-container) {
  background: var(--maskbg);
  padding: 0.25rem;
  border-bottom-left-radius: var(--card-border-radius);
  border-bottom-right-radius: var(--card-border-radius);
}

.c-product-info-dialog {
  @media (min-width: 550px) {
    max-width: 450px;
  }
}

.c-product-info-container {
  max-height: 8rem;
  overflow-y: auto;
}
:deep(.c-float-input-container) {
  padding: 0 1rem;
}
:deep(.c-float-wrapper input:not(:placeholder-shown) ~ .c-floating-label) {
  left: 1rem;
}
:deep(.c-floating-label) {
  left: 1rem;
}
</style>
