<template>
  <div class="field mt-6">
    <DataTable
      data-key="id"
      :value="productSupplierPriceList"
      responsive-layout="scroll"
      selection-mode="single"
      data-testid="product-supplier-price-table"
      class="c-supplier-price-table c-datatable"
      striped-rows
      scrollable
      scroll-height="12rem"
      :pt="{
        bodyrow: 'bg-slate-50 dark:bg-primary-400/10 text-base',
        bodyCell: 'p-0 m-0',
      }"
    >
      <Column field="supplierName" :header="t('product-supplier-price.supplier-name')" style="width: 60%">
        <template #body="{ data, field, index }">
          <div class="text-ellipsis whitespace-nowrap overflow-hidden" :data-testid="`supplier-name-${index}`">
            {{ data[field as keyof typeof data] }}
          </div>
        </template>
      </Column>

      <Column field="replacementCost" :header="t('product-supplier-price.product-cost-price')" style="width: 40%">
        <template #body="{ data, field, index }">
          <div class="flex justify-end" :data-testid="`product-replacement-cost-${index}`">
            {{ n(data[field as keyof typeof data], "currency", data.currencyIso) }}
          </div>
        </template>
      </Column>

      <template #empty>
        <div class="flex justify-start">
          {{ t(productId == null ? "product-supplier-price.select-product" : "product-supplier-price.not-found") }}
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ProductSupplierPrice } from "@/models/product/ProductSupplierPrice";
import { useProduct } from "@/api/product/ProductService";
import { ref, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

const props = defineProps<{
  productId: string | null;
}>();

const { getProductSupplierPrices } = useProduct();

const productSupplierPriceList = ref<ProductSupplierPrice[]>();

watch(
  () => props.productId,
  async (newValue) => await fetchAvailability(newValue),
);

onMounted(async () => await fetchAvailability(props.productId));

const fetchAvailability = async (productId: string | null) => {
  if (productId == null) {
    productSupplierPriceList.value = [];
    return;
  }

  const result = await getProductSupplierPrices(productId);
  productSupplierPriceList.value = result;
};
</script>

<style scoped lang="scss">
.c-datatable.p-datatable :deep(.p-datatable-tbody > tr > td) {
  padding: 0.2rem 0.3rem;
}

.c-input-date {
  border: none;

  &:disabled {
    background-color: transparent;
    color: var(--text-color);
    text-align: right;
    font-family: var(--font-family);
    font-size: 1rem;
    font-weight: normal;
  }
}
</style>
