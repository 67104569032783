import { Warehouse, useWarehouseApi, ProductAvailability } from "@multicase/portal-api/warehouse";
import { type AuthHeaders } from "@multicase/portal-api";

export function useWarehouses() {
  const warehouses = ref<Warehouse[]>([]);

  const { getRequest, error, loading } = useRequests();
  const warehouseApi = useWarehouseApi();

  const fetchWarehouses = async (): Promise<Warehouse[]> => {
    await getRequest(async (authHeaders: AuthHeaders) => {
      warehouses.value = await warehouseApi.getAll(authHeaders);
    });
    return warehouses.value;
  };

  const getAvailability = async (productId: string): Promise<ProductAvailability[]> => {
    const result = await getRequest(async (authHeaders: AuthHeaders) => {
      return await warehouseApi.getAvailability(authHeaders, productId);
    });
    return result ?? [];
  };

  onMounted(fetchWarehouses);

  return {
    warehouses,
    loading,
    error,
    fetchWarehouses,
    getAvailability,
  };
}
