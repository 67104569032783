<template>
  <FloatLabel variant="on">
    <Select
      id="payment-term"
      v-model="selectedPaymentTerm"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('order.payment-term').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :options="dropdownOptions"
      option-label="name"
      option-value="value"
      data-testid="default-payment-term"
      class="w-full"
      :class="{ 'p-invalid': val.paymentTerm.$error, 'p-disabled': !allowEdit }"
      :invalid="val.$error"
    />
    <label for="payment-term">
      {{ t(`order.payment-term`) }}
    </label>
  </FloatLabel>

  <small v-if="val.$error" class="ml-6 p-error" data-testid="default-payment-term-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import type { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import { usePaymentTerms } from "@/api/payment-term/PaymentTermService";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { OrderPaymentTerm } from "@/models/order/OrderPaymentTerm";
import { OrderStatus } from "@/models/order/OrderStatus";

const { getPaymentTerms } = usePaymentTerms();

const props = defineProps<{
  paymentTerm: OrderPaymentTerm;
  paymentTermIdFromCustomer: string;
  orderStatus?: OrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:paymentTerm", value: OrderPaymentTerm): void;
}>();

const dropdownOptions = computed(() => {
  return paymentTerms.value.map((paymentTerm) => {
    return { name: paymentTerm.name, value: paymentTerm.id };
  });
});

const selectedPaymentTerm = computed<string>({
  get: () => {
    return paymentTerms.value.find((pm) => pm.id === props.paymentTerm?.id)?.id ?? "";
  },
  set: (id: string) => {
    if (id === "") {
      return;
    }

    const pm = paymentTerms.value.find((pm) => pm.id === id);
    if (pm && allowEdit.value === true) {
      emit("update:paymentTerm", new OrderPaymentTerm(pm.id, pm.name, pm.creditDays));
    }
  },
});

watch(
  () => props.paymentTermIdFromCustomer,
  (id: string) => (selectedPaymentTerm.value = id),
);

const paymentTerms = ref<PaymentTerm[]>([]);
const loadPaymentTerms = async () => {
  paymentTerms.value = await getPaymentTerms();
};

onMounted(loadPaymentTerms);

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});

const rules = {
  paymentTerm: {
    name: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);
const { t } = useI18n();
</script>
