<template>
  <Dialog
    v-model:visible="visible"
    :header="t('order.address.dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    style="width: 25vw"
    @keydown.esc="handleEscape"
  >
    <div class="px-2">
      <div class="grid grid-cols-12 gap-4 pt-2">
        <div class="col-span-12">
          <FloatLabel variant="on">
            <InputText
              id="delivery-customer-name"
              v-model="delivery.customerName"
              data-testid="delivery-customer-name"
              type="text"
              class="inputfield w-full"
              :class="{ 'p-invalid': val.customerName.$error }"
            />
            <label for="delivery-customer-name">{{ t("order.address.customer-name") }}</label>
          </FloatLabel>
          <small v-if="val.customerName.$error" class="p-error" data-testid="delivery-customer-name-error">
            {{ val.customerName.$errors[0].$message }}
          </small>
        </div>

        <div class="col-span-12 flex gap-4">
          <div class="flex-1 text-left">
            <FloatLabel variant="on">
              <InputText
                id="delivery-email"
                v-model="delivery.email"
                data-testid="edit-delivery-email"
                type="text"
                class="inputfield w-full"
              />
              <label for="delivery-email">{{ t("order.address.email") }}</label>
            </FloatLabel>
          </div>
          <div class="flex-none w-1/3 text-left">
            <FloatLabel variant="on">
              <InputText
                id="delivery-phone-number"
                v-model="delivery.phoneNumber"
                data-testid="edit-delivery-phone-number"
                type="text"
                class="inputfield w-full"
              />
              <label for="delivery-phone-number">{{ t("order.address.phone-number") }}</label>
            </FloatLabel>
          </div>
        </div>
        <div class="col-span-12">
          <FloatLabel variant="on">
            <Textarea
              id="delivery-address-lines"
              v-model="addressLinesComputed"
              v-tooltip.focus.bottom="{
                value: t('placeholder.type', { property: t('order.address.lines').toLowerCase() }),
                showDelay: 1000,
                hideDelay: 300,
              }"
              class="inputfield w-full"
              :class="{ 'p-invalid': val.address.addressLines.$error }"
              data-testid="edit-delivery-address-lines"
              :auto-resize="false"
              rows="4"
            />
            <!-- :autoResize="true" create this bug: https://github.com/vuejs/vue-cli/issues/7431 -->

            <label for="delivery-address-lines">{{ t("order.address.lines") }}</label>
          </FloatLabel>
          <small v-if="val.address.addressLines.$error" class="p-error" data-testid="delivery-address-lines-error">
            {{ val.address.addressLines.$errors[0].$message }}
          </small>
        </div>
        <div class="col-span-12">
          <FloatLabel variant="on">
            <Select
              id="address-country"
              v-model="delivery.address.countryIso"
              v-tooltip.focus.bottom="{
                value: t('placeholder.type', { property: t('order.address.country').toLowerCase() }),
                showDelay: 1000,
                hideDelay: 300,
              }"
              :options="countries"
              data-testid="delivery-country"
              option-label="name"
              option-value="iso"
              class="inputfield w-full"
              :class="{ 'p-invalid': val.address.countryIso.$error }"
              @show="isActive = true"
              @hide="isActive = false"
              @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
            />
            <label for="address-country">{{ t("order.address.country") }}</label>
          </FloatLabel>
          <small v-if="val.address.countryIso.$error" id="address-country-error" class="p-error">{{
            val.address.countryIso.$errors[0].$message
          }}</small>
        </div>
        <div class="col-span-12 flex gap-4">
          <div class="flex-none w-40 text-left">
            <FloatLabel variant="on">
              <InputText
                v-model="delivery.address.postalCode"
                v-tooltip.focus.bottom="{
                  value: t('placeholder.type', { property: t('order.address.postal-code').toLowerCase() }),
                  showDelay: 1000,
                  hideDelay: 300,
                }"
                data-testid="edit-delivery-postal-code"
                maxlength="4"
                type="text"
                class="inputfield w-full"
                :class="{ 'p-invalid': val.address.postalCode.$error }"
                @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
              />
              <label for="delivery-address-postal-code">{{ t("order.address.postal-code") }}</label>
            </FloatLabel>
            <small
              v-if="val.address.postalCode.$error"
              class="p-error"
              data-testid="delivery-address-postal-code-error"
              >{{ val.address.postalCode.$errors[0].$message }}</small
            >
          </div>
          <div class="flex-1 text-left">
            <FloatLabel variant="on">
              <InputText
                v-model="delivery.address.city"
                v-tooltip.focus.bottom="{
                  value: t('placeholder.type', { property: t('order.address.city').toLowerCase() }),
                  showDelay: 1000,
                  hideDelay: 300,
                }"
                data-testid="delivery-address-city"
                type="text"
                class="inputfield w-full"
                :class="{ 'p-invalid': val.address.city.$error }"
              />
              <label for="delivery-address-city">{{ t("order.address.city") }}</label>
            </FloatLabel>
            <small v-if="val.address.city.$error" class="p-error" data-testid="delivery-address-city-error">{{
              val.address.city.$errors[0].$message
            }}</small>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-end flex-wrap card-container purple-container mt-6 mr-6">
        <Button
          :label="t(`common.cancel`)"
          data-testid="delivery-dialog-cancel-btn"
          class="mr-4"
          severity="cancel"
          text
          tabindex="1"
          autofocus
          icon="pi pi-times"
          @click="handleEscape"
        />
        <Button :label="t('common.save')" data-testid="delivery-dialog-save-btn" icon="pi pi-check" @click="onSave" />
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";
import cloneDeep from "lodash.clonedeep";
import { computed, nextTick, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { Delivery } from "@/models/order/Delivery";
import { type CountryInfo } from "@multicase/portal-api/country";

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
  currentDelivery: Delivery;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
  (e: "updateDeliveryInformation", value: Delivery): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const { fetchCityByPostalCode } = useCountries();

const delivery = ref<Delivery>(props.currentDelivery ? cloneDeep(props.currentDelivery) : new Delivery());
const isActive = ref(false);

const addressLinesComputed = computed<string>({
  get: () => {
    return delivery.value.address.addressLines.join("\n");
  },
  set: (val) => {
    delivery.value.address.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    return;
  }

  emit("updateDeliveryInformation", delivery.value);
  handleEscape();
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    return;
  }

  const response = await fetchCityByPostalCode(code);
  delivery.value.address.city = response ?? "";
};

const rules = {
  customerName: {
    required,
  },
  address: {
    addressLines: {
      required,
      $each: helpers.withMessage(t("validations.required"), (value: string[]) => {
        return value !== undefined && value[0].length > 1;
      }),
    },
    postalCode: {
      required,
    },
    city: {
      required,
    },
    countryIso: {
      required,
    },
  },
};

const val = useVuelidate(rules, delivery.value);
const handleEscape = async () => {
  visible.value = false;
  await nextTick();
  document.getElementById("order-reference")?.focus();
};

const countries = ref<CountryInfo[]>([]);

const { fetchCountries } = useCountries();
const fetchCountryData = async () => {
  countries.value = await fetchCountries();
};

onMounted(() => {
  fetchCountryData();
});
</script>
