import { OrderProduct } from "./product/OrderProduct";
import { v4 as uuid, NIL as emptyUuid } from "uuid";
import { SearchProductViewModel } from "@/models/search/product/view-model/SearchProductViewModel";

export class OrderLine {
  id: string = uuid();
  positionNumber = 0;
  product = new OrderProduct();
  quantity = 0;
  quantityDelivered = 0;
  quantityForDelivery = 0;
  quantityInBackOrder = 0;
  quantityAllocatedForPicking = 0;
  backOrderDate = new Date().toJSON();
  shippingDate = new Date().toJSON();
  fifoDate = new Date().toJSON();
  listPrice = 0;
  price = 0;
  priceIncVat = 0;
  vatPercentage = 0;
  costPrice = 0;
  contributionMargin = 0;
  discountPercentage = 0;
  sumLine = 0;
  warehouseId: string = emptyUuid;

  public static create(orderProduct: OrderProduct, product: SearchProductViewModel) {
    const orderLine = new OrderLine();
    orderLine.product = orderProduct;
    orderLine.quantity = 1;
    orderLine.vatPercentage =
      product.taxes.find((t) => t.taxType === "Outgoing" && t.countryIso === "NO")?.taxRate ?? 0;

    orderLine.listPrice = product.prices[0]?.listPrice ?? 0;
    orderLine.price = orderLine.listPrice;
    orderLine.priceIncVat = product.prices[0]?.listPriceIncVat ?? 0;
    orderLine.costPrice = product.availability.find((a) => a.productId === product.id)?.costPrice ?? 0;
    orderLine.discountPercentage = product.prices[0]?.discountPercentage ?? 0;
    orderLine.contributionMargin = product.prices[0]?.contributionMargin ?? 0;

    return orderLine;
  }
}
