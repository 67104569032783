export function useOrderRouteService(orderId: Ref<string>) {
  const router = useRouter();

  const routeToOrderSearch = async () => {
    if (window.history.state.back === null || window.history.state.back.indexOf("/order/search") === -1) {
      router.push({ name: "order-search", query: { search: "" } });
    } else {
      const url = new URL(window.location.origin + window.history.state.back);
      url.searchParams.set("focusResult", orderId.value);
      router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
    }
  };

  const paramsToObject = (entries: URLSearchParams) => {
    const result = {} as Record<string, string>;
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  return { routeToOrderSearch };
}
