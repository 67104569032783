import { type AuthHeaders } from "@multicase/portal-api";
import { PackingNote, usePackingNoteApi } from "@multicase/portal-api/packing-note";

export function usePackingNote() {
  const packingNotes = ref<PackingNote[]>([]);

  const { getRequest, error, loading } = useRequests();
  const packingNoteApi = usePackingNoteApi();

  const fetchPackingNotes = async (orderId: string): Promise<PackingNote[]> => {
    const result = await getRequest(async (authHeaders: AuthHeaders) => {
      return await packingNoteApi.getPackingNote(authHeaders, orderId);
    });
    return result ?? [];
  };

  return {
    packingNotes,
    loading,
    error,
    fetchPackingNotes,
  };
}
