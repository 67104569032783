<template>
  <OrderHeader
    :edit-mode="false"
    :show-sidebar="showSidebar"
    :is-order-changed="isOrderChanged"
    :is-saving="isSaving"
    :order-status="currentOrder.orderStatus"
    @toggle-sidebar-clicked="toggleSidebar"
    @on-cancel="routeToOrderSearch"
    @on-commit="onSave"
  />
  <div
    class="ml-2"
    :class="[
      showSidebar && !useOverlaySidebar ? 'c-show-sidebar' : 'c-no-sidebar',
      { useOverlaySidebar: 'c-overlay-sidebar' },
    ]"
  >
    <div class="c-content">
      <div class="flex wrap my-2">
        <div class="c-content-top-left mb-2">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap px-4">
                <div class="c-col-1 pr-8">
                  <div class="mt-3 mb-6">
                    <CustomerInput
                      v-model:focus-search-input="focusCustomerSearchInput"
                      :customer="currentOrder.customer"
                      :order-lines="currentOrder.orderLines"
                      @selected-customer-id="addOrderStore.setActiveCustomerById"
                    />
                  </div>

                  <div class="mb-6">
                    <ContactInput
                      :contact="currentOrder.customer.contact"
                      :customer-contacts="customerContacts"
                      @selected-contact="selectedContact"
                    />
                  </div>

                  <div class="my-4 -mx-6">
                    <ContactEmailInput v-model:email="currentOrder.customer.contact.email" />
                  </div>
                </div>

                <div class="c-col-2">
                  <div class="-mt-1.5">
                    <InformationTabs
                      v-model:delivery="currentOrder.delivery"
                      :customer="currentOrder.customer"
                      :allow-edit="true"
                      :invoice-address="currentOrder.invoiceAddress"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right mb-2">
          <Card class="c-font-size h-full mx-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6 -mx-4 mt-1">
                  <div class="mb-6">
                    <OrderReference
                      v-model:order-reference="currentOrder.orderReference"
                      :allow-edit-order="allowEditOrder"
                    />
                  </div>

                  <div class="mb-6 mx-8">
                    <PaymentTerms
                      v-model:payment-term="currentOrder.paymentTerm"
                      :payment-term-id-from-customer="activeCustomer?.payment.defaultPaymentTermId ?? ''"
                    />
                  </div>

                  <div class="mb-6 mx-8">
                    <FreightMethod
                      v-model:freight-method="currentOrder.freightMethod"
                      :default-freight-method-id="activeCustomer?.freight.defaultFreightMethodId ?? ''"
                      @re-calculate-order-total-price="addOrderStore.reCalculateOrderTotalPrice"
                    />
                  </div>
                </div>

                <div class="c-col-2 -mx-4 mt-1">
                  <div class="mb-3.5 mx-6">
                    <ShippingPriceInput
                      v-model:shipping-price="currentOrder.shippingPrice"
                      v-model:agreed-freight="currentOrder.agreedFreight"
                      :order-status="currentOrder.orderStatus"
                      :freight-method="currentOrder.freightMethod"
                      currency-iso="NOK"
                      @shipping-calculation-dialog-visible="shippingCalculationDialogVisible = $event"
                    />
                  </div>

                  <div class="mb-6">
                    <CommentInput v-model:comment="currentOrder.comment" />
                  </div>

                  <div class="mt-4 mb-6 -mr-4">
                    <DeliveryOption
                      v-model:delivery-option="currentOrder.deliveryOption"
                      :allow-edit-order="allowEditOrder"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
      <div class="c-order-lines-content">
        <Card class="c-font-size c-order-lines-card m-2" pt:body:class="pt-2">
          <template #content>
            <div class="c-order-tables">
              <OrderLines
                v-if="!showAdvancedSearch"
                v-model:order-lines="currentOrder.orderLines"
                v-model:selected-product-id="selectedProductId"
                v-model:focus-first-order-line="focusFirstOrderLine"
                :show-advanced-search="showAdvancedSearch"
                :allow-add-products="allowAddProducts"
                :allow-edit-order="allowEditOrder"
                :order-status="currentOrder.orderStatus"
              />

              <ProductSearch
                ref="productSearchRef"
                v-model:show-advanced-search="showAdvancedSearch"
                v-model:selected-product-id="selectedProductId"
                v-model:focus-first-order-line="focusFirstOrderLine"
                :allow-add-products="allowAddProducts"
                :allow-edit-order="allowEditOrder"
                :order-lines="currentOrder.orderLines"
                :customer-id="currentOrder.customer?.id"
                :default-warehouse-id="defaultWarehouseId"
                @create-new-order-line="addOrderStore.addOrderLine($event.orderLine, $event.warehouseId)"
              />
            </div>
          </template>
        </Card>
      </div>
      <OrderFooter
        v-model:shipping-price="currentOrder.shippingPrice"
        v-model:agreed-freight="currentOrder.agreedFreight"
        :calculated-order-total="calculatedOrderTotal"
        :order-status="currentOrder.orderStatus"
        :orderlines="currentOrder.orderLines.length"
        :show-sidebar="showSidebar"
        :use-overlay-sidebar="useOverlaySidebar"
        @re-calculate-order-total="addOrderStore.reCalculateOrderTotalPrice"
      />
    </div>

    <Card v-if="!useOverlaySidebar" class="c-right-bar c-font-size m-2">
      <template #content>
        <OrderInfoSidebar
          v-model:current-order="currentOrder"
          :allow-edit-order="allowEditOrder"
          :selected-product-id="selectedProductId"
        />
      </template>
    </Card>

    <Drawer v-else v-model:visible="showSidebar" position="right" class="md:w-80 lg:w-[30rem]">
      <OrderInfoSidebar
        v-model:current-order="currentOrder"
        :allow-edit-order="allowEditOrder"
        :selected-product-id="selectedProductId"
      />
    </Drawer>
  </div>

  <Dialog
    v-model:visible="shippingCalculationDialogVisible"
    :modal="true"
    :header="t('common.shipping-dialog-header')"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="c-checkout-dialog w-3/12"
    data-testid="calculate-shipping-modal"
    @show="calculateParcels"
  >
    <Parcels v-model:parcels="parcels" @recalculate-bring="onRecalculateBring" />
    <div class="col-span-12 md:col-span-12 lg:col-span-12">
      <BringError :errors="shippingGuideErrors" />
    </div>
    <FreightSummary
      :parcels="parcels"
      :weight="weight"
      :cost="shippingGuideErrors.length > 0 ? 0.0 : bringPriceExVat"
    />

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            tabindex="47"
            class="c-dialog-default-button"
            @click="shippingCalculationDialogVisible = false"
          />

          <Button
            :label="t('common.confirm')"
            data-testid="btn-confirm-checkout"
            class="c-dialog-default-button c-dialog-success-button"
            tabindex="46"
            @click="onConfirmBringShipping"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut, useUnsavedChanges } from "@cumulus/components";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useAddOrderStore } from "@/stores/AddOrderStore";
import { useOrderRouteService } from "@/api/order/OrderRouteService";
import { useOrderSidebar } from "@/components/sidebar/OrderSidebar";
import { Warehouse } from "@multicase/portal-api/warehouse";
import { useBringStore } from "@/stores/BringStore";
import { Parcel } from "@/models/bring/Parcel";
import { OrderLine } from "@/models/order/OrderLine";
import { OrderContact } from "@/models/order/OrderContact";

const onConfirmBringShipping = () => {
  currentOrder.value.shippingPrice = bringPriceExVat.value;
  shippingCalculationDialogVisible.value = false;
  addOrderStore.reCalculateOrderTotalPrice();
};
const calculateParcels = () => {
  parcels.value = [new Parcel(1, calculateWeight(currentOrder.value.orderLines))];
  onRecalculateBring();
};
const calculateWeight = (orderLines: OrderLine[]) => {
  return orderLines.reduce((acc, orderLine) => acc + orderLine.product.weight.value * orderLine.quantity, 0);
};

const { fetchWarehouses } = useWarehouses();
const warehouse = ref<Warehouse>();

const addOrderStore = useAddOrderStore();
const {
  currentOrder,
  isOrderChanged,
  calculatedOrderTotal,
  allowEditOrder,
  customerContacts,
  activeCustomer,
  selectedProductId,
  isSaving,
  focusFirstOrderLine,
  allowAddProducts,
} = storeToRefs(addOrderStore);

const showAdvancedSearch = ref(false);

const defaultWarehouseId = ref<string>("");
onMounted(async () => {
  defaultWarehouseId.value = await addOrderStore.getDefaultWarehouseId();
});

const bringStore = useBringStore();
const { weight, parcels, bringPriceExVat, shippingGuideErrors } = storeToRefs(bringStore);

const orderId = computed(() => currentOrder.value.id);
const { routeToOrderSearch } = useOrderRouteService(orderId);
const { showSidebar, useOverlaySidebar, toggleSidebar } = useOrderSidebar();

const { t } = useI18n();
const focusCustomerSearchInput = ref(true);

const productSearchRef = ref();
const shippingCalculationDialogVisible = ref(false);

// Nested validation
const val = useValidate();
const { addToast, SeverityType } = useErrorHandler();

const onSave = async () => {
  //Wait for input onblur functions and calculations to finish
  if (document.activeElement?.localName === "input") {
    (document.activeElement as HTMLElement).blur();
  }

  val.value.$touch();
  if (!(await val.value.$validate())) {
    addToast(SeverityType.Warning, t("order.add.toast.validation.summary"), t("order.add.toast.validation.detail"));
    return;
  }

  if (await addOrderStore.createOrder()) {
    resetView();
  }
};

const onRecalculateBring = () => {
  if (currentOrder.value.freightMethod.bring == null) return;
  if (weight.value === 0) return;
  if (warehouse.value == null) return;

  try {
    bringStore.getBringShippingGuide(currentOrder.value, warehouse.value);
  } catch {
    addToast(SeverityType.Warning, t("checkout.error"), t("checkout.error-getting-shipping-guide"));
  }
};

const resetView = async () => {
  val.value.$reset();
  window.scrollTo(0, 0);
  await addOrderStore.clearStore();
  productSearchRef.value.reset();
  focusCustomerSearchInput.value = true;
};

onMounted(async () => {
  await addOrderStore.clearStore();
  const warehouses = await fetchWarehouses();
  if (warehouses.length > 0 && warehouses[0] != null) {
    warehouse.value = warehouses[0];
  }
});

useShortcut(ShortcutAction.save, onSave);

const selectedContact = (contact: OrderContact) => {
  currentOrder.value.customer.contact = contact;
};

useUnsavedChanges(isOrderChanged);
</script>

<style lang="scss" scoped>
.c-content-top-left {
  flex: 50%;
}
.c-content-top-right {
  flex: 50%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-invoice-address {
  @media (min-width: 992px) {
    min-height: 7rem;
    background-image: linear-gradient(to bottom, #000 10%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 1px 6px;
    background-repeat: repeat-y;
  }
}

.c-order-lines-card {
  min-height: calc(100vh - 29rem);
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.c-font-size {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
  width: calc(100% - 26rem);
}

.c-right-bar {
  position: fixed;
  top: 7rem;
  bottom: 2rem;
  right: 1rem;
  width: 25rem;
  transition: all 0.25s;
}

.c-no-sidebar {
  .c-content {
    width: 100%;
    transition: all 0.25s;
  }
}

.c-no-sidebar .c-right-bar {
  margin-right: -28rem;
}

.is-mobile .c-content .c-font-size {
  margin: 0.6rem 0;
}

.c-tool-material .material-symbols-outlined {
  padding: 2px;
  font-weight: 500;
}
.c-freight-button {
  height: 32px;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: black !important;
  background-color: var(--p-card-background) !important;
  border: none;
}
.c-transport-icon {
  background: var(--p-primary-color);
  color: white;
}
</style>
