<template>
  <DataTable
    ref="contactTableRef"
    :value="contacts"
    scrollHeight="flex"
    dataKey="id"
    selectionMode="single"
    :rowHover="true"
    data-testid="contact-search-list"
    sortField="name"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="true"
    :rows="10"
    class="c-datatable"
    @row-select="onRowSelect"
  >
    <template #header>
      <span class="p-input-icon-left">
        <i class="pi pi-search absolute mt-2" />
        <InputText
          id="contactSearchInput"
          ref="searchInputRef"
          v-model="query"
          v-debounce:200="search"
          :placeholder="t('order.contact.search-placeholder')"
          class="w-full pl-8"
          autocomplete="off"
          data-testid="contact-search-list-input"
          @focus="selectAllOnFocus"
          @keydown.enter="searchInputEnterKey"
          @keypress.tab="focusFirstSearchResult"
          @keydown.down="focusFirstSearchResult"
        />
      </span>
    </template>

    <Column field="firstName" :header="t('order.contact.firstname')"> </Column>
    <Column field="lastName" :header="t('order.contact.firstname')"> </Column>
    <Column field="phoneNumber" :header="t('order.contact.phone')"> </Column>
    <Column field="email" :header="t('order.contact.email')"> </Column>
    <Column field="isDefaultContact" :header="t('order.contact.default-contact')" class="text-center">
      <template #body="{ data }">
        <i v-if="data.isDefaultContact" class="pi pi-check-circle"></i>
      </template>
    </Column>

    <template #empty>
      <div>
        {{
          query.length < 1
            ? t("order.contact.empty-search-query")
            : t("order.contact.no-contacts-found", { search: query })
        }}
      </div>
    </template>

    <template #footer> </template>
  </DataTable>

  <div class="flex justify-between items-center flex-wrap">
    <div class="flex">
      {{
        t("order.customer.search-footer-result", {
          searchCount: contacts.length,
          totalSearchCount: totalHits,
        })
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { DataTableRowSelectEvent } from "primevue/datatable";
import { OrderContact } from "@/models/order/OrderContact";
import { CustomerContact } from "@/models/customer/CustomerContact";

const { t } = useI18n();

const props = defineProps<{ contact: OrderContact; customerContacts: CustomerContact[] }>();
const emit = defineEmits<{ (e: "selectedContact", value: CustomerContact): void }>();

const contactTableRef = ref();

const focusFirstSearchResult = (event: Event) => {
  event.stopPropagation();
  contactTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const searchInputEnterKey = (event: Event) => {
  event.stopPropagation();

  if (query.value.length < 1) {
    focusFirstSearchResult(event);
    return;
  }

  //if only one result, select it
  if (contacts.value.length === 1) {
    emit("selectedContact", contacts.value[0]);
    return;
  }

  //Create a new contact if no result
  if (contacts.value.length === 0) {
    const customerContact = new CustomerContact();
    const name = query.value.trim().split(" ");
    customerContact.lastName = name.length > 1 ? (name.pop() as string) : "";
    customerContact.firstName = name.join(" ");
    emit("selectedContact", customerContact);
  }

  focusFirstSearchResult(event);
  return;
};

const onRowSelect = async (event: DataTableRowSelectEvent) => {
  const customerContact = event.data as CustomerContact;
  if (!customerContact) return;

  emit("selectedContact", customerContact);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const totalHits = ref(0);
const query = ref<string>("");

const contacts = ref<CustomerContact[]>([]);
const search = async (q: string) => {
  if (q.length < 1 || q.trim() === "*") {
    contacts.value = props.customerContacts;
    totalHits.value = props.customerContacts.length;
    return;
  }
  query.value = q;

  contacts.value = props.customerContacts.filter(
    (c) =>
      c.firstName.toLowerCase().includes(q.toLowerCase()) ||
      c.lastName.toLowerCase().includes(q.toLowerCase()) ||
      (c.firstName.toLowerCase() + " " + c.lastName.toLowerCase()).includes(q.toLowerCase()) ||
      c.email.toLowerCase().includes(q.toLowerCase()),
  );

  totalHits.value = props.customerContacts.length;
};

onMounted(async () => {
  const name = props.contact?.firstName + " " + props.contact?.lastName;
  await search(name.trim());
});
</script>

<style lang="scss">
.c-contact-panel {
  .c-no-search-result {
    color: var(--pink-700);
  }

  .p-datatable-header {
    padding: 0;
    margin-bottom: 0.5rem;

    .p-inputtext {
      border: none;
      border-bottom: 2px solid var(--p-focus-ring-color); //--floating-input-border-on-focus => 2px solid var(--active-component-color); =>  --active-component-color: #2680EB;
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-color: var(--p-focus-ring-color); //var(--active-component-color);
      }
    }
    .p-input-icon-left > i:first-of-type {
      color: var(--p-focus-ring-color); //var(--active-component-color);
      font-weight: bolder;
    }
  }

  .p-datatable-footer {
    padding: 0.75rem 0;
  }
}
</style>
