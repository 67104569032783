<template>
  <FloatLabel variant="on">
    <InputText
      id="contact-search-input"
      v-model="contactName"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('order.contact.label').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      class="c-contact-dropdown"
      :class="{ 'p-invalid': val.contact.firstName.$error, 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
      data-testId="contact-search-input"
      :pt:overlay:class="'hidden'"
      :fluid="true"
      @click="showContactPanel"
    />
    <label for="contact-search-input">{{ t("order.contact.label") }}</label>
  </FloatLabel>
  <small v-if="val.contact.$error" class="ml-6 p-error" data-testid="custom-contact-error">
    {{ val.contact.$errors[0].$message }}
  </small>

  <ContactPanel
    ref="contactPanelRef"
    :contact="contact"
    :customer-contacts="props.customerContacts"
    @contact-selected="contactSelected"
  />
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import ContactPanel from "./ContactPanel.vue";
import { OrderContact } from "@/models/order/OrderContact";
import { CustomerContact } from "@/models/customer/CustomerContact";
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { OrderStatus } from "@/models/order/OrderStatus";

const { t } = useI18n();

const props = defineProps<{
  customerContacts: CustomerContact[];
  orderStatus?: OrderStatus;
}>();

const contact = defineModel<OrderContact>("contact", { required: true });

const contactName = computed<string>(() => {
  return (contact.value?.firstName + " " + contact.value?.lastName).trim();
});

const emit = defineEmits<{
  (e: "selectedContact", value: CustomerContact): void;
}>();

const contactSelected = (selectedContact: CustomerContact) => {
  emit("selectedContact", selectedContact);

  nextTick(() => {
    document.getElementById("contact-email")?.focus();
  });
};

const contactPanelRef = ref();
const showContactPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  contactPanelRef.value.toggle(event);
};

const rules = {
  contact: {
    firstName: {
      required,
    },
  },
};

const val = useVuelidate(rules, { contact });

const allowEdit = computed<boolean>(() => {
  return !props.orderStatus || props.orderStatus === OrderStatus.Open;
});
</script>
<style scoped lang="scss">
.c-contact-dropdown {
  width: 100%;
}
</style>
